import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs";
import DashboardLayout from "../layouts/DashboardLayout";
import Header from "../components/HeaderComanda";

const Comanda = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const userId = localStorage.getItem("userid");
    if (!userId) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <DashboardLayout>
      <Breadcrumbs
        items={[{ label: "Comanda", link: "/comanda" }]}
        showKitchenLink={true}
      />
      <hr className="solid" />
      <Header />
    </DashboardLayout>
  );
};

export default Comanda;
