import React, { useState, useEffect } from "react";
import { saveMenuColor, getMenuColor } from "../services/authService";
import Button from "./Button";

const MenuColors = () => {
  const defaultColor = "#1c2434"; // Color predeterminado si no hay color guardado en la base de datos
  const [menuColor, setMenuColor] = useState(defaultColor);
  const [savedColor, setSavedColor] = useState(defaultColor);

  useEffect(() => {
    const restaurantId = localStorage.getItem("userid"); // Obtiene restaurantId dentro de useEffect

    if (!restaurantId) {
      console.log("restaurantId no disponible en localStorage.");
      return; // Si no hay restaurantId, no se ejecuta el fetch
    }

    const fetchMenuColor = async () => {
      try {
        const response = await getMenuColor(restaurantId);

        if (response) {
          const fetchedColor = response;

          // Si existe un color guardado en la base de datos, úsalo; si no, usa el color predeterminado
          const finalColor = fetchedColor || defaultColor;
          setSavedColor(finalColor);
          setMenuColor(finalColor);
        } else {
          console.log("No se encontró color en la base de datos.");
          setSavedColor(defaultColor);
          setMenuColor(defaultColor);
        }
      } catch (error) {
        console.error("Error al obtener el color del menú:", error);
        setSavedColor(defaultColor);
        setMenuColor(defaultColor);
      }
    };

    fetchMenuColor();
  }, []); // Array vacío asegura que useEffect se ejecute solo una vez al cargar el componente

  const handleColorChange = (e) => {
    const newColor = e.target.value;
    setMenuColor(newColor);
  };

  const handleSaveColor = async () => {
    const restaurantId = localStorage.getItem("userid"); // Asegura obtener el restaurantId al guardar también
    if (!restaurantId) return;

    try {
      const response = await saveMenuColor(restaurantId, menuColor);
      console.log("Save response:", response);
      setSavedColor(menuColor); // Actualiza el color guardado
    } catch (error) {
      console.error("Error al guardar el color:", error);
    }
  };

  return (
    <div>
      <h3>Colores del Menú</h3>
      <label>Selecciona el color que se utilizará en el menú principal.</label>
      <input type="color" value={menuColor} onChange={handleColorChange} />
      <div style={{ marginTop: "10px" }}>
        <Button
          label="Guardar color!"
          variant="default"
          size="medium"
          onClick={handleSaveColor}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "150px",
        }}
      >
        <div
          style={{
            padding: "58px",
            width: "54px",
            height: "54px",
            borderRadius: "50%",
            backgroundColor: savedColor,
            display: "inline-block",
          }}
          title="Color del menú"
        ></div>
      </div>
    </div>
  );
};

export default MenuColors;
