import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import styles from "../css/perfil.module.css";
import { updatePaymentMethod } from "../services/authService";

// Clave pública de Stripe
const stripePromise = loadStripe(
  "pk_live_51NgaVrBijbKqi14Wr6RtDQS5D1lrL5NXL2uG5GVybcQSNAjoqMphxFNzewXwNBUN1wPDqsJjNvtPUpuvxPb8qnr600E7NpJ63U",
);

const PaymentForm = () => {
  const [name, setName] = useState("");
  const [paymentError, setPaymentError] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null);

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    const storedUserId = localStorage.getItem("userid");
    setUserId(storedUserId);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setPaymentError(null);
    setPaymentSuccess(false);

    if (!stripe || !elements) {
      setPaymentError("Stripe no está cargado correctamente.");
      return;
    }
    if (!userId) {
      setPaymentError("No se encontró el ID de usuario.");
      return;
    }

    setLoading(true);

    try {
      // 🚀 1. Crear el método de pago en Stripe
      const cardElement = elements.getElement(CardElement);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: { name },
      });

      if (error) {
        throw new Error(error.message);
      }

      console.log("✅ Método de pago creado:", paymentMethod.id);

      // 🚀 2. Enviar el paymentMethod al backend para guardarlo en la base de datos
      const response = await updatePaymentMethod(userId, paymentMethod.id);

      if (!response.success) {
        throw new Error("No se pudo guardar el método de pago en el servidor.");
      }

      setPaymentSuccess(true);
    } catch (error) {
      console.error("❌ Error:", error.message);
      setPaymentError(error.message || "Error al procesar el método de pago.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <h2>Registrar Método de Pago</h2>
      <form onSubmit={handleSubmit} id="payment-form">
        <div className="inputGroup">
          <label htmlFor="card-holder-name">Nombre del titular</label>
          <input
            type="text"
            id="card-holder-name"
            placeholder="Nombre en la tarjeta"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>

        <div className="cardElementContainer">
          <label htmlFor="card-element">Número de tarjeta</label>
          <CardElement id="card-element" className="cardElement" />
        </div>

        <button className="submitbtn" type="submit" disabled={loading}>
          {loading ? "Guardando..." : "Guardar Método de Pago"}
        </button>
      </form>

      {paymentError && <div className="errormessage">{paymentError}</div>}
      {paymentSuccess && (
        <div className="successmessage">
          ✅ Método de pago guardado con éxito.
        </div>
      )}
    </div>
  );
};

const PaymentPage = () => (
  <Elements stripe={stripePromise}>
    <PaymentForm />
  </Elements>
);

export default PaymentPage;
