import React from "react";
import { useLocation, Link } from "react-router-dom";
import "./AdminPanel.css";

const Sidebar = ({ menuOpen }) => {
  const location = useLocation(); // Hook para obtener la ruta actual

  return (
    <div className={`sidebar ${menuOpen ? "open" : ""}`}>
      <h2>Pallevar</h2>

      {/* Enlace para Dashboard */}
      <Link
        to="/dashboard"
        className={location.pathname === "/dashboard" ? "active" : ""}
      >
        <i className="fas fa-tachometer-alt"></i> Panel de control
      </Link>

      {/* Enlace para Menu */}
      <Link
        to="/menu"
        className={location.pathname === "/menu" ? "active" : ""}
      >
        <i className="fas fa-utensils"></i> Menu
      </Link>
      {/*}
      <Link to="/comanda" className={location.pathname === '/comanda' ? 'active' : ''}>
      <i className="fas fa-clipboard-list"></i> Comanda
      </Link>
      {*/}
      {/* Enlace para Órdenes */}
      <Link
        to="/ordenes"
        className={location.pathname === "/ordenes" ? "active" : ""}
      >
        <i className="fas fa-receipt"></i> Órdenes
      </Link>
      <Link
        to="/compartir"
        className={location.pathname === "/compartir" ? "active" : ""}
      >
        <i className="fas fa-share"></i> Compartir
      </Link>
      {/* Enlace para Configuración */}
      <Link
        to="/configuracion"
        className={location.pathname === "/configuracion" ? "active" : ""}
      >
        <i className="fas fa-cog"></i> Configuración
      </Link>

      <Link
        to="https://docs.espallevar.com/docs/category/panel-administrativo/"
        target="_blanks"
      >
        <i className="fas fa-book"></i> Documentación
      </Link>
    </div>
  );
};

export default Sidebar;
