import React, { useState, useEffect } from "react";
import style from "../css/ConfigPage.module.css";
import MenuColors from "../components/MenuColors";
import AccountSection from "../components/AccountSection";
import MetodoPago from "../components/MetodoPago";
import DetalleRestaurante from "../components/DetalleRestaurante";
import OperatingHoursForm from "../components/OperatingHoursForm";
import Sucursales from "../components/Sucursales";

const ConfigPage = () => {
  const [selectedSection, setSelectedSection] = useState("metodoPago");
  const [idUsuario, setIdUsuario] = useState(null);
  const [tipoPlan, setTipoPlan] = useState(null);
  useEffect(() => {
    const id = localStorage.getItem("userid");
    const tipoPlan = localStorage.getItem("plan");
    //  console.log(tipoPlan);
    setIdUsuario(id);
    setTipoPlan(tipoPlan);
  }, []);
  const renderSection = () => {
    switch (selectedSection) {
      case "accountSection":
        return <CuentasPersonal id={idUsuario} plan={tipoPlan} />;
      case "detalleRestaurante":
        return <DetalleRestauranteSection id={idUsuario} />;
      case "sucursales":
        return <RestauranteSucursales id={idUsuario} plan={tipoPlan} />;
      case "notifications":
        return <NotificationsSection />;
      case "metodoPago":
        return <MetodoPagoSection />;
      case "menuColors":
        return <MenuColorsSection />;
      case "openingHours":
        return <OpeningHours id={idUsuario} />;
      default:
        return <DetalleRestauranteSection id={idUsuario} />;
    }
  };

  return (
    <div className={style.configPage}>
      <div className={style.sidebar}>
        <h2>Configuración</h2>
        <ul>
          <li
            onClick={() => setSelectedSection("metodoPago")}
            className={`${style.sidebarItem} ${selectedSection === "metodoPago" ? style.active : ""}`}
          >
            <i className="fas fa-credit-card"></i> Metodo de Pagos
          </li>
          <li
            onClick={() => setSelectedSection("detalleRestaurante")}
            className={`${style.sidebarItem} ${selectedSection === "detalleRestaurante" ? style.active : ""}`}
          >
            <i className="fas fa-info-circle"></i> Detalles del restaurante
          </li>
          <li
            onClick={() => setSelectedSection("sucursales")}
            className={`${style.sidebarItem} ${selectedSection === "sucursales" ? style.active : ""}`}
          >
            <i className="fas fa-store"></i> Sucursales
          </li>
          <li
            onClick={() => setSelectedSection("accountSection")}
            className={`${style.sidebarItem} ${selectedSection === "accountSection" ? style.active : ""}`}
          >
            <i className="fas fa-user"></i> Cuentas del personal
          </li>
          <li
            onClick={() => setSelectedSection("openingHours")}
            className={`${style.sidebarItem} ${selectedSection === "openingHours" ? style.active : ""}`}
          >
            <i className="fas fa-clock"></i> Horario de Operación
          </li>
          {/*}
                    <li
                        onClick={() => setSelectedSection('notifications')}
                        className={`${style.sidebarItem} ${selectedSection === 'notifications' ? style.active : ''}`}
                    >
                        Notificaciones
                    </li>
                    {*/}
          <li
            onClick={() => setSelectedSection("menuColors")}
            className={`${style.sidebarItem} ${selectedSection === "menuColors" ? style.active : ""}`}
          >
            <i className="fas fa-palette"></i> Colores del Menú
          </li>
        </ul>
      </div>
      <div className={style.content}>{renderSection()}</div>
    </div>
  );
};

// Componentes de cada sección de configuración

const CuentasPersonal = ({ id, plan }) => {
  return <AccountSection idUsuario={id} tipoPlan={plan} />;
};

const DetalleRestauranteSection = ({ id }) => {
  return <DetalleRestaurante idUsuario={id} />;
};

const RestauranteSucursales = ({ id, plan }) => {
  return <Sucursales idUsuario={id} tipoPlan={plan} />;
};

const NotificationsSection = () => (
  <div>
    <h3>Notificaciones</h3>
    <div>
      <input type="checkbox" id="orderUpdates" />
      <label htmlFor="orderUpdates">Actualizaciones de pedidos</label>
    </div>
    <div>
      <input type="checkbox" id="promoUpdates" />
      <label htmlFor="promoUpdates">Ofertas y Promociones</label>
    </div>
    <div>
      <input type="checkbox" id="appUpdates" />
      <label htmlFor="appUpdates">Actualizaciones de la App</label>
    </div>
  </div>
);

const MetodoPagoSection = () => {
  return <MetodoPago />;
};

const MenuColorsSection = () => {
  return <MenuColors />;
};

const OpeningHours = ({ id }) => {
  return <OperatingHoursForm idUsuario={id} />;
};
export default ConfigPage;
