import React, { useState, useEffect } from "react";
import Button from "./Button";
import Alert from "../components/Alert";
import {
  getPersonal,
  createUserPersonal,
  deletePersonal,
} from "../services/authService";

const AccountSection = ({ idUsuario, tipoPlan }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAlertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState(""); // Estado para el mensaje de la alerta
  const [isDeleteAlertVisible, setDeleteAlertVisible] = useState(false); // Alerta de eliminación
  const [personalDelete, setPersonalDelete] = useState(null);

  const [newUser, setNewUser] = useState({
    username: "",
    email: "",
    password: "",
    restaurante: "",
  });

  const handleAddUser = async (e) => {
    e.preventDefault();

    // Verificar si el plan es "básico" y si ya hay 2 usuarios
    if (tipoPlan === "basico" && users.length >= 2) {
      setAlertMessage(
        "Has alcanzado el límite de usuarios para el plan básico. Por favor, actualiza tu plan.",
      );
      setAlertVisible(true); // Mostrar la alerta
      return; // Detener la ejecución
    }

    const restaurantId = localStorage.getItem("userid");

    try {
      // Crear el nuevo usuario en el backend
      const createdUser = await createUserPersonal(
        newUser.username,
        newUser.email,
        newUser.password,
        restaurantId,
      );

      if (createdUser) {
        // Actualizar la lista de usuarios con el nuevo usuario
        setUsers([
          ...users,
          { nombre: newUser.username, email: newUser.email },
        ]);
        // Limpiar el formulario
        setNewUser({ username: "", email: "", password: "" });
      }
    } catch (error) {
      console.error("Error al agregar el usuario:", error);
      setAlertMessage(
        "Ocurrió un error al agregar el usuario. Intenta nuevamente.",
      );
      setAlertVisible(true); // Mostrar la alerta en caso de error
    }
  };

  const handleCloseAlert = () => {
    setAlertVisible(false); // Cerrar la alerta de límite de usuarios
    setAlertMessage(""); // Limpiar el mensaje
  };

  const handleOpenDeleteModal = (idItem) => {
    setPersonalDelete(idItem);
    setDeleteAlertVisible(true); // Mostrar la alerta de eliminación
  };

  const handleConfirmDelete = () => {
    if (personalDelete) {
      handleDeletePersonal(personalDelete);
      setDeleteAlertVisible(false); // Cerrar la alerta de confirmación
      setPersonalDelete(null); // Limpiar el estado
    }
  };

  const handleCancelDelete = () => {
    setDeleteAlertVisible(false); // Cerrar la alerta de confirmación
    setPersonalDelete(null); // Limpiar el estado en caso de cancelación
  };

  const handleDeletePersonal = async (idItem) => {
    try {
      const response = await deletePersonal(idItem);
      if (response) {
        await getUsers();
      }
    } catch (error) {
      console.error("Error al eliminar el usuario:", error);
      setAlertMessage("Error al eliminar el usuario. Intenta nuevamente.");
      setAlertVisible(true);
    }
  };

  const getUsers = async () => {
    try {
      const restaurantId = localStorage.getItem("userid");
      const usersData = await getPersonal(restaurantId);
      if (usersData) {
        setUsers(usersData);
      }
    } catch (error) {
      console.error("Error al obtener los usuarios:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, [idUsuario, tipoPlan]);

  return (
    <div>
      <h3>Cuentas del personal</h3>

      {/* Tabla de usuarios */}
      <table border="1" style={{ width: "100%", marginBottom: "20px" }}>
        <thead>
          <tr>
            <th>Nombre de Usuario</th>
            <th>Correo Electrónico</th>
            <td colSpan="2"></td>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="2" style={{ textAlign: "center" }}>
                Cargando el personal...
              </td>
            </tr>
          ) : users.length > 0 ? (
            users.map((user, index) => (
              <tr key={index}>
                <td>{user.nombre}</td>
                <td>{user.email}</td>
                <td>
                  <Button
                    label={<i className="fas fa-edit"></i>}
                    variant="received"
                    size="smaller"
                  />
                </td>
                <td>
                  <Button
                    label={<i className="fas fa-trash"></i>}
                    variant="canceled"
                    size="smaller"
                    onClick={() => handleOpenDeleteModal(user._id)}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2" style={{ textAlign: "center" }}>
                No hay usuarios disponibles.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Formulario para agregar un nuevo usuario */}
      <div>
        <h4>Agregar nuevo usuario</h4>
        <form onSubmit={handleAddUser}>
          <div>
            <label>Nombre de Usuario</label>
            <input
              type="text"
              placeholder="Ingresa el nombre de usuario"
              value={newUser.username}
              onChange={(e) =>
                setNewUser({ ...newUser, username: e.target.value })
              }
              required
            />
          </div>

          <div>
            <label>Correo Electrónico</label>
            <input
              type="email"
              placeholder="Ingresa el correo electrónico"
              autoComplete="username"
              value={newUser.email}
              onChange={(e) =>
                setNewUser({ ...newUser, email: e.target.value })
              }
              required
            />
          </div>

          <div>
            <label>Contraseña</label>
            <input
              type="password"
              placeholder="Ingresa la contraseña"
              autoComplete="current-password"
              value={newUser.password}
              onChange={(e) =>
                setNewUser({ ...newUser, password: e.target.value })
              }
              required
            />
          </div>

          <div>
            <Button label="Agregar Usuario" variant="default" size="medium" />
          </div>
        </form>
      </div>

      {/* Alerta para límite de usuarios */}
      <Alert isVisible={isAlertVisible} label={alertMessage}>
        <Button
          label="Cerrar"
          variant="default"
          size="large"
          onClick={handleCloseAlert}
        />
      </Alert>

      {/* Alerta de confirmación de eliminación */}
      <Alert
        isVisible={isDeleteAlertVisible}
        label="Estás seguro de eliminar este usuario?"
      >
        <Button
          label="Confirmar"
          variant="canceled"
          size="medium"
          onClick={handleConfirmDelete}
        />
        <Button
          label="Cancelar"
          variant="default"
          size="medium"
          onClick={handleCancelDelete}
        />
      </Alert>
    </div>
  );
};

export default AccountSection;

/*}
      <Alert
        label="Estás seguro de eliminar este usuario?"
        isVisible={isAlertVisible}
      >
        <Button
          label="Confirmar"
          variant="canceled"
          size="medium"
          onClick={handleConfirmDelete}
        />
        <Button
          label="Cancelar"
          variant="default"
          size="medium"
          onClick={handleCancelDelete}
        />
      </Alert>
      {*/
