import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import DashboardLayout from "../layouts/DashboardLayout";
//import styles from '../css/config.module.css';
//import Button from '../components/Button';
import ConfigPage from "./ConfigPage";

const Configuracion = () => {
  return (
    <DashboardLayout>
      <Breadcrumbs
        items={[{ label: "Configuracion", link: "/configuracion" }]}
      />
      <hr className="solid" />
      <ConfigPage />
    </DashboardLayout>
  );
};

export default Configuracion;
