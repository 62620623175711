import React, { useState, useEffect } from "react";
import styles from "../css/conectarStripe.module.css";
import { status } from "../services/authService";

const MetodoPago = () => {
  const [stripeStatus, setStripeStatus] = useState(null); // Estado de conexión
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Verificar el estado de la cuenta Stripe del restaurante desde tu backend
    const fetchStripeStatus = async () => {
      const restaurantId = localStorage.getItem("userid");
      try {
        const response = await status(restaurantId); //axios.get('/api/stripe/status'); // Endpoint en tu backend
        console.log(response.data.connected);
        setStripeStatus(response.data); // Ejemplo: { connected: true, email: 'example@mail.com' }
        //  console.log("stripeStatus:", stripeStatus);
      } catch (error) {
        console.error("Error al verificar el estado de Stripe:", error);
      }
    };

    fetchStripeStatus();
  }, []);

  const handleConnectStripe = async () => {
    // console.log("stripeStatus:", stripeStatus);
    // console.log(stripeStatus.onboardingUrl);
    //window.location.href = stripeStatus.onboardingUrl;
    window.open(stripeStatus.onboardingUrl, "_blank", "noopener,noreferrer");
    setLoading(true);
    //try {
    // Solicitar un enlace de onboarding al backend
    //const response = await stripeOnboard(0);//axios.post('/api/stripe/onboard'); // Endpoint en tu backend
    //  window.location.href = response.data.url; // Redirige al enlace generado
    //console.group(response)
    //} catch (error) {
    //console.error('Error al generar el enlace de Stripe:', error);
    //} finally {
    //setLoading(false);
    //}
  };

  return (
    <div className={styles.container}>
      <h2>Conectar Stripe</h2>
      <p>
        Conecta tu cuenta de Stripe para que puedas recibir pagos de tus
        clientes directamente en tu cuenta bancaria.
      </p>

      {/* Estado de conexión */}
      <div className={styles.statusBox}>
        {stripeStatus ? (
          stripeStatus.connected ? (
            <p className={styles.connected}>
              ✅ Conectado: {stripeStatus.email}
            </p>
          ) : (
            <p className={styles.notConnected}>❌ No conectado</p>
          )
        ) : (
          <p>Cargando estado...</p>
        )}
      </div>

      {/* Botón para conectar o actualizar Stripe */}
      <div className={styles.buttonContainer}>
        <button
          className={styles.connectButton}
          onClick={handleConnectStripe}
          disabled={loading}
        >
          {loading
            ? "Cargando..."
            : stripeStatus?.connected
              ? "Actualizar información"
              : "Configurar metodo de pagos"}
        </button>
      </div>

      <div className={styles.instructions}>
        <p>
          <strong>Nota:</strong> Serás redirigido a Stripe para conectar tu
          cuenta. Una vez terminado, <strong>regresa a esta página</strong> para
          confirmar la conexión.
        </p>
      </div>
    </div>
  );
};

export default MetodoPago;
