import React, { useState, useEffect } from "react";
import Button from "./Button";
import { restInfo, updateRestInfo } from "../services/authService";

const DetalleRestaurante = ({ idUsuario }) => {
  const [detalleRest, setDetalleRest] = useState({
    nombreRestaurante: "",
    direccion: "",
    numeroTelefono: "",
    logo: null,
  });

  const [isUpdating, setIsUpdating] = useState(false); // Estado para controlar el botón de actualización

  useEffect(() => {
    const infoRestaurante = async () => {
      try {
        // console.log("idUsuario:", idUsuario);
        const response = await restInfo(idUsuario);
        setDetalleRest({
          nombreRestaurante: response.data.nombreRestaurante || "",
          direccion: response.data.direccion || "",
          numeroTelefono: response.data.telefono || "",
          logo: null, // No se maneja el logo aquí, solo la referencia
        });
        // console.log(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    infoRestaurante();
  }, [idUsuario]);

  // Manejar cambios en los inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetalleRest((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Manejar carga de archivos
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setDetalleRest((prevState) => ({
      ...prevState,
      logo: file,
    }));
  };

  // Enviar los datos actualizados
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("Datos enviados:", detalleRest);

    // Validar nombreRestaurante
    const regex = /^[a-zA-Z0-9_-]+$/; // Solo alfabeticos, alfanumericos y guiones
    const regex2 = /^\d+$/; // no permitir solo digitos
    if (
      !regex.test(detalleRest.nombreRestaurante) ||
      regex2.test(detalleRest.nombreRestaurante)
    ) {
      console.log("nombreRestaurante inalido.");
      alert("Nombre del Restaurante no es valido.");
      return; // No actualizar el estado si el valor no es válido
    }

    // Crear un objeto FormData para manejar la subida de archivos
    const formData = new FormData();
    formData.append("nombreRestaurante", detalleRest.nombreRestaurante);
    formData.append("direccion", detalleRest.direccion);
    formData.append("numeroTelefono", detalleRest.numeroTelefono);

    if (detalleRest.logo) {
      formData.append("logo", detalleRest.logo);
    }

    try {
      // Activar el estado de actualización
      setIsUpdating(true);

      // Llamar a la función updateRestInfo con FormData
      const response = await updateRestInfo(
        idUsuario,
        detalleRest.nombreRestaurante,
        detalleRest.direccion,
        detalleRest.numeroTelefono,
        detalleRest.logo,
      );
      console.log("Respuesta del servidor:", response);
    } catch (error) {
      console.error("Error al actualizar los datos:", error);
    } finally {
      // Desactivar el estado de actualización
      setIsUpdating(false);
    }
  };

  return (
    <div>
      <h3>Personalización</h3>
      <form onSubmit={handleSubmit}>
        <label>Nombre de la tienda</label>
        <input
          type="text"
          name="nombreRestaurante"
          placeholder="Nombre de la tienda"
          value={detalleRest.nombreRestaurante}
          onChange={handleChange}
        />

        <label>Dirección</label>
        <input
          type="text"
          name="direccion"
          placeholder="Agrega la dirección"
          value={detalleRest.direccion}
          onChange={handleChange}
        />

        <label htmlFor="image">Logo del restaurante</label>
        <input type="file" id="image" name="logo" onChange={handleFileChange} />

        <label>Numero de teléfono</label>
        <input
          type="tel"
          name="numeroTelefono"
          placeholder="000-000-0000"
          value={detalleRest.numeroTelefono}
          onChange={handleChange}
        />

        <div>
          <Button
            type="submit"
            label={isUpdating ? "Actualizando..." : "Guardar"} // Cambiar el texto del botón según el estado
            variant="default"
            size="medium"
            disabled={isUpdating} // Desactivar el botón mientras se actualiza
          />
        </div>
      </form>
    </div>
  );
};

export default DetalleRestaurante;
