import React, { useState } from "react";
import { updateuserInfo } from "../services/authService";

const RestaurantModal = ({ isOpen, userName, userID }) => {
  const [restaurantName, setRestaurantName] = useState("");
  const [restaurantType, setRestaurantType] = useState("");
  const [inputFocused, setInputFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Estado para el loader

  const handleSubmit = async () => {
    if (restaurantName && restaurantType) {
      // Validar nombreRestaurante
      const regex = /^[a-zA-Z0-9_-]+$/; // Solo alfabeticos, alfanumericos y guiones
      const regex2 = /^\d+$/; // no permitir solo digitos
      if (!regex.test(restaurantName) || regex2.test(restaurantName)) {
        console.log("restaurantName inalido.");
        alert("Nombre del Restaurante no es valido.");
        return; // No actualizar el estado si el valor no es válido
      }
      const formattedName = restaurantName.toLowerCase().replace(/\s+/g, "");
      setIsLoading(true); // Mostrar el loader
      const response = await updateuserInfo(
        userID,
        formattedName,
        restaurantType,
      );
      console.log(response);

      setIsLoading(false); // Ocultar el loader
      window.location.reload(); // Refrescar la página
    } else {
      alert("Por favor, ingresa el tipo y nombre de tu restaurante.");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal" style={styles.modal}>
      <div className="modal-content" style={styles.modalContent}>
        <div className="modal-header">
          <h2>¡Bienvenido a Pallevar, {userName}!</h2>
        </div>
        <div className="modal-body">
          <label htmlFor="restaurantName">
            Ingresa el nombre de tu establecimiento:
          </label>
          <select
            required
            value={restaurantType}
            onChange={(e) => setRestaurantType(e.target.value)}
          >
            <option>Seleccionar opcion</option>
            <option value="restaurant">Restaurant</option>
            <option value="tienda">Tienda</option>
          </select>
          <input
            type="text"
            id="restaurantName"
            value={restaurantName}
            onChange={(e) => setRestaurantName(e.target.value)}
            placeholder="Nombre del restaurante"
            required
            style={{
              ...styles.input,
              borderColor: inputFocused ? "#0065FF" : "#ccc",
            }}
            onFocus={() => setInputFocused(true)}
            onBlur={() => setInputFocused(false)}
          />
        </div>
        <div className="modal-footer" style={styles.modalFooter}>
          <button
            onClick={handleSubmit}
            style={styles.button}
            disabled={isLoading}
          >
            {isLoading ? "Actualizando..." : "Guardar"}
          </button>
        </div>
      </div>
    </div>
  );
};

// Estilos en línea para el modal
const styles = {
  modal: {
    display: "flex",
    position: "fixed",
    zIndex: 1000,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    overflow: "auto",
    backgroundColor: "rgba(0,0,0,0.7)",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContent: {
    backgroundColor: "#fefefe",
    margin: "15% auto",
    padding: "20px",
    border: "1px solid #888",
    borderRadius: "8px",
    maxWidth: "400px",
    width: "90%",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  },
  modalFooter: {
    display: "flex",
    justifyContent: "center",
    marginTop: "15px",
  },
  button: {
    backgroundColor: "#0065FF",
    color: "white",
    border: "none",
    padding: "10px 15px",
    borderRadius: "5px",
    cursor: "pointer",
    transition: "background-color 0.3s",
  },
};

export default RestaurantModal;
