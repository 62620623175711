// DishCard.js
import React from "react";
import "./DishCard.css"; // Estilos personalizados para las tarjetas
import Button from "./Button";

const DishCard = ({
  name,
  price,
  description,
  image,
  onDelete,
  onEdit,
  idItem,
  //onClick,
  //ocultar,
}) => {
  return (
    <div className="dish-card">
      <img src={image} alt={name} className="dish-card-image" />
      <div className="dish-card-content">
        <h3>{name}</h3>
        <p>{description}</p>
        <span>${price}</span>
        <Button
          label="Editar"
          variant="received"
          size="small"
          onClick={() => onEdit(idItem)}
        />
        &nbsp;
        <Button
          label="Eliminar"
          variant="canceled"
          size="small"
          onClick={() => onDelete(idItem)}
        />
      </div>
    </div>
  );
};

export default DishCard;
