import { useState, useEffect } from "react";
import styles from "../css/sucursal.module.css";

const Sucursal = ({ label, estado, onChange }) => {
  const [isAvailable, setIsAvailable] = useState(estado);

  useEffect(() => {
    // Sincronizamos el estado local con el valor de la prop `estado` cuando cambia
    setIsAvailable(estado);
  }, [estado]);

  const handleChange = () => {
    const newStatus = !isAvailable;
    setIsAvailable(newStatus);
    if (onChange) {
      onChange(newStatus); // Llamamos al onChange para pasar el nuevo estado al componente padre
    }
  };

  return (
    <div className={styles.sucursal}>
      <label htmlFor="sucursal">{label}</label>
      <label className={styles.switch}>
        <input
          type="checkbox"
          id="sucursal"
          checked={isAvailable}
          onChange={handleChange}
          aria-checked={isAvailable}
        />
        <span className={`${styles.slider} ${styles.round}`}></span>
      </label>
      <span
        className={`${styles.estado} ${isAvailable ? styles.disponible : styles.noDisponible}`}
      >
        {isAvailable ? "Disponible" : "No disponible"}
      </span>
    </div>
  );
};

export default Sucursal;
