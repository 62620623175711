// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import Login from "./components/Login";
import NewUser from "./components/NewUser";
import PasswordReset from "./components/PasswordReset";
import ResetPassword from "./components/ResetPassword";
import AdminPanel from "./components/AdminPanel";
import Menu from "./pages/Menu";
import Comanda from "./pages/Comanda";
import Ordenes from "./pages/Ordenes";
import Configuracion from "./pages/Configuracion";
import Perfil from "./pages/Perfil";
import Planes from "./pages/Planes";
import Compartir from "./pages/Compartir";
import ProtectedRoute from "./components/ProtectedRoute";
function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          {/* Definición de las rutas */}
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<NewUser />} />
            <Route path="/password-reset" element={<PasswordReset />} />
            <Route
              path="/password-reset/:tokenpass"
              element={<ResetPassword />}
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <AdminPanel />
                </ProtectedRoute>
              }
            />
            <Route
              path="/menu"
              element={
                <ProtectedRoute>
                  <Menu />
                </ProtectedRoute>
              }
            />
            <Route
              path="/comanda"
              element={
                <ProtectedRoute>
                  <Comanda />
                </ProtectedRoute>
              }
            />
            <Route
              path="/ordenes"
              element={
                <ProtectedRoute>
                  <Ordenes />
                </ProtectedRoute>
              }
            />
            <Route
              path="/configuracion"
              element={
                <ProtectedRoute>
                  <Configuracion />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <Perfil />
                </ProtectedRoute>
              }
            />
            <Route
              path="/planes"
              element={
                <ProtectedRoute>
                  <Planes />
                </ProtectedRoute>
              }
            />
            <Route
              path="/compartir"
              element={
                <ProtectedRoute>
                  <Compartir />
                </ProtectedRoute>
              }
            />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
