import React, { useState, useEffect } from "react";
import { newPassword } from "../services/authService";
import { useNavigate } from "react-router-dom";
import LoadingDots from "./LoadingDots";
import { useParams } from "react-router-dom";
import "./styles.css";

const ResetPassword = () => {
  const { tokenpass } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const validateLength = (password) => {
    return password.length >= 8;
  };

  const validatePassword = (password, confirmPassword) => {
    return password === confirmPassword;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");

    if (!validateLength(password)) {
      setError("La contraseña debe tener al menos 8 caracteres.");
      return;
    }

    if (!validatePassword(password, confirmPassword)) {
      setError("Las contraseñas no coinciden.");
      return;
    }

    setLoading(true);
    try {
      const response = await newPassword(password, tokenpass);

      if (response) {
        setSuccess(true);
      } else {
        setError("Error en la respuesta del servidor.");
      }
    } catch (error) {
      setError(
        error.response?.data?.message ||
          "Error al restablecer la contraseña. Inténtalo de nuevo.",
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="left-side">
        <img
          src="/img/logo_blanco_fondo_transparente.png"
          alt="Logo"
          className="full-screen-logo"
        />
      </div>

      <div className="right-side login-container">
        <img
          src="/img/pallevar-logo-2.PNG"
          alt="Logo"
          className="small-screen-logo"
        />

        {success ? (
          <div className="successMessage">
            <h1>Tu contraseña se ha actualizado</h1>
            <div className="forgot-password">
              <a href="/">Haz clic aquí para iniciar sesión</a>
            </div>
          </div>
        ) : (
          <>
            <h1>Establecer Nueva Contraseña</h1>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="password">Nueva contraseña</label>
                <input
                  type="password"
                  id="password"
                  placeholder="Contraseña"
                  value={password}
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>Confirmar contraseña:</label>
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="login-button" disabled={loading}>
                {loading ? <LoadingDots /> : "Restablecer contraseña"}
              </button>
              {error && <div className="error-message">{error}</div>}
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
