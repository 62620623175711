import React, { useState, useEffect } from "react";
import AdminLayout from "../layouts/DashboardLayout";
import Breadcrumbs from "../components/Breadcrumbs";
import RestaurantModal from "../components/RestaurantModal";
import { dashboardInfo, obtieneOrdenes } from "../services/authService";
import styles from "../css/tablero.module.css";
import LoadingDots from "./LoadingDots";
import tabla from "../css/ordenes.module.css";

const AdminPanel = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [userID, setUserID] = useState("");
  const [items, setItems] = useState([]);
  const [totalOrdenes, setTotalOrdenes] = useState([]);
  const [totalVentas, setTotalVentas] = useState([]);
  const [ordenesPendientes, setOrdenesPendientes] = useState([]);
  const [isLoadingItems, setIsLoadingItems] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const nombre = localStorage.getItem("nombre");
    const userid = localStorage.getItem("userid");
    setUserName(nombre);
    setUserID(userid);

    if (userid) {
      const fetchDashboardData = async () => {
        try {
          const response = await dashboardInfo(userid);
          // console.log("Restaurante:", response);

          // Validar si el restaurante está vacío
          if (!response.data.restaurante) {
            /*console.log(
              "Restaurante no encontrado:",
              response.data.restaurante,
            );*/
            setModalOpen(true); // Mostrar modal si no hay restaurante
            return; // Salir de la función sin ejecutar más
          }

          // Si hay restaurante, no mostrar modal y ejecutar las demás funciones
          //console.log("Restaurante encontrado:", response.data.restaurante);
          setModalOpen(false);
          setItems(response.data.items);
          setTotalOrdenes(response.data.totalOrdenes);
          setTotalVentas(response.data.totalVentas);

          // Obtener órdenes pendientes
          const ordenesResponse = await obtieneOrdenes(userid);
          const pendientes = ordenesResponse
            ?.filter((orden) => orden.orderStatus === "Pendiente") // Filtrar órdenes pendientes
            ?.slice(-5); // Tomar las últimas 5
          setOrdenesPendientes(pendientes || []);
        } catch (error) {
          console.error(
            "Error al obtener la información del dashboard:",
            error,
          );
        } finally {
          setIsLoadingItems(false);
          setLoading(false);
        }
      };

      fetchDashboardData();
    }
  }, []);

  const breadcrumbItems = [
    { label: "Inicio", link: "/" },
    { label: "Panel de Administración", link: "/admin" },
    { label: "Agregar Platillo", link: "/admin/agregar-platillo" },
  ];

  return (
    <>
      <AdminLayout breadcrumbItems={breadcrumbItems}>
        <Breadcrumbs items={[{ label: "Tablero", link: "/dashboard" }]} />
        <hr className="solid" />
        <section className={styles.dashboardSection}>
          <div className={styles.cardContainer}>
            <div className={styles.card}>
              <p>Total de Ítems en Menú</p>
              <h3>{isLoadingItems ? <LoadingDots /> : items || 0}</h3>
            </div>
            <div className={styles.card}>
              <p>Total de Órdenes del Día</p>
              <h3>{isLoadingItems ? <LoadingDots /> : totalOrdenes || 0}</h3>
            </div>
            <div className={styles.card}>
              <p>Ingresos del Día</p>
              <h3>
                {" "}
                {isLoadingItems ? (
                  <LoadingDots />
                ) : (
                  `$ ${totalVentas} MXN` || `$ ${0} MXN`
                )}{" "}
              </h3>
            </div>
            <div className={styles.card}>
              <p>Órdenes Pendientes</p>
              <h3>
                {isLoadingItems ? (
                  <LoadingDots />
                ) : (
                  ordenesPendientes.length || 0
                )}
              </h3>
            </div>
          </div>
        </section>
        <section className={styles.dashboardSection}>
          <h2>Órdenes Recientes</h2>
          <div className={tabla.tableContainer}>
            <table>
              <thead>
                <tr>
                  <th># Orden</th>
                  <th>Pedido</th>
                  <th>Comentarios</th>
                  <th>Estatus</th>
                  <th>Datos Cliente</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="11" style={{ textAlign: "center" }}>
                      Cargando órdenes
                    </td>
                  </tr>
                ) : ordenesPendientes.length > 0 ? (
                  ordenesPendientes.map((orden) => (
                    <tr key={orden.orderId}>
                      <td># {orden.orderId}</td>
                      <td>
                        <div className={tabla.itemsContainer}>
                          {orden.items.map((item, index) => (
                            <div key={index} className={tabla.item}>
                              <strong>{item.nombrePlatillo}</strong> - Cantidad:{" "}
                              {item.quantity}, Precio: ${item.precio}
                            </div>
                          ))}
                        </div>
                      </td>
                      <td className={tabla.clienteCell}>{orden.comments}</td>
                      <td>
                        <span
                          className={`${tabla.status} ${tabla[orden.orderStatus]}`}
                        >
                          {orden.orderStatus}
                        </span>
                      </td>
                      <td>
                        <div>
                          <strong>Cliente:</strong> {orden.contactName}
                        </div>
                        <div>
                          <strong>Total:</strong> ${orden.totalPrice}
                        </div>
                        <div>
                          <strong>Método de pago:</strong> {orden.paymentMethod}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="11" style={{ textAlign: "center" }}>
                      No hay órdenes pendientes.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </section>
      </AdminLayout>

      <RestaurantModal
        isOpen={isModalOpen}
        userName={userName}
        userID={userID}
      />
    </>
  );
};

export default AdminPanel;
