import React, { useEffect, useState } from "react";
import styles from "../css/ordenes.module.css";
import DashboardLayout from "../layouts/DashboardLayout";
import Breadcrumbs from "../components/Breadcrumbs";
import { obtieneOrdenes } from "../services/authService";
import tabla from "../css/tablero.module.css";

const Ordenes = () => {
  const [ordenes, setOrdenes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const restaurantNombre = localStorage.getItem("userid");
    // console.log(restaurantNombre);
    const fetchOrdenes = async () => {
      try {
        const response = await obtieneOrdenes(restaurantNombre);
        // console.log(response);
        setOrdenes(response || []);
      } catch (error) {
        console.error("Error al obtener las órdenes:", error);
      } finally {
        setLoading(false); // Finaliza la carga, ya sea que tenga éxito o no
      }
    };
    fetchOrdenes();
  }, []);

  return (
    <DashboardLayout>
      <Breadcrumbs
        items={[{ label: "Ordenes", link: "/ordenes" }]}
        showKitchenLink={true}
      />
      <hr className="solid" />
      <p></p>
      <section className={tabla.dashboardSection}>
        <div className={tabla.tableContainer}>
          <table>
            <thead>
              <tr>
                <th># Orden</th>
                <th>Pedido</th>
                <th>Comentarios</th>
                <th>Estatus</th>
                <th>Datos Cliente</th>
                <th>Fecha</th>
                <th>Hora</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="11" style={{ textAlign: "center" }}>
                    Cargando órdenes...
                  </td>
                </tr>
              ) : (
                ordenes.map((orden) => (
                  <tr key={orden.orderId}>
                    <td># {orden.orderId}</td>
                    <td>
                      <div className={styles.itemsContainer}>
                        {orden.items.map((item, index) => (
                          <div key={index} className={styles.item}>
                            <strong>{item.nombrePlatillo}</strong> - Cantidad:{" "}
                            {item.quantity}, Precio: ${item.precio}
                          </div>
                        ))}
                      </div>
                    </td>
                    <td className={styles.clienteCell}>{orden.comments}</td>
                    <td>
                      <span
                        className={`${styles.status} ${styles[orden.orderStatus]}`}
                      >
                        {orden.orderStatus}
                      </span>
                    </td>
                    <td>
                      <div>
                        <strong>Cliente:</strong> {orden.contactName}
                      </div>
                      <div>
                        <strong>Total:</strong> ${orden.totalPrice}
                      </div>
                      <div>
                        <strong>Método de pago:</strong> {orden.paymentMethod}
                      </div>
                    </td>
                    <td>
                      {orden.orderTimestamp.split(" ")[0]} {/* Fecha */}
                    </td>
                    <td>
                      {orden.orderTimestamp.split(" ")[1]} {/* Hora */}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </section>
    </DashboardLayout>
  );
};

export default Ordenes;
