import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs";
import DishCard from "../components/DishCard";
import DashboardLayout from "../layouts/DashboardLayout";
import {
  getMenuItems,
  NewItemOnMenu,
  deleteMenuItem,
  editaItemOnMenu,
  //ocultaItemOnMenu,
  getSucursales,
} from "../services/authService";
import Alert from "../components/Alert";
import Button from "../components/Button";
import style from "../css/menu.module.css";
import Sucursal from "../components/sucursal";

const Menu = () => {
  const userId = localStorage.getItem("userid");
  const [branches, setBranches] = useState([]);
  const [dishes, setDishes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingModal, setLoadingModal] = useState(false); // Nuevo estado para el loader en el modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAlertVisible, setAlertVisible] = useState(false); // Estado para controlar la visibilidad de la alerta
  const [dishToDelete, setDishToDelete] = useState(null);
  const [formValues, setFormValues] = useState({
    name: "",
    description: "",
    price: "",
    image: "",
    category: "",
    estadoSucursal: "",
    disponibilidadSucursales: [],
  });
  const [modalButtonValues, setModalButtonValues] = useState({
    value: "Agregar platillo", // Valor por defecto
    variant: "received", // Variante por defecto
  });
  //const [isSucursalAvailable, setIsSucursalAvailable] = useState(true);

  const navigate = useNavigate(); // Redirigir si no hay sesión

  useEffect(() => {
    const userId = localStorage.getItem("userid");
    if (!userId) {
      navigate("/login"); // Redirigir si no hay sesión
    }
  }, [navigate]);

  const loadDishes = useCallback(async () => {
    setLoading(true);
    try {
      if (!userId) {
        throw new Error("No se encontró el userId");
      }
      const dishesData = await getMenuItems(userId);
      //console.log("Platillos:", dishesData);
      setDishes(dishesData);
    } catch (error) {
      console.error("Error al cargar los platillos:", error);
    } finally {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    loadDishes();
  }, [loadDishes]);

  const handleDeleteDish = async (idItem) => {
    try {
      const response = await deleteMenuItem(idItem);
      if (response) {
        await loadDishes(); // Recargar los platillos
      }
    } catch (error) {
      console.error("Error al eliminar el platillo:", error);
      alert("Error al eliminar el platillo. Intenta nuevamente.");
    }
  };

  const handleOpenDeleteModal = (idItem) => {
    // console.log("delete:", idItem);
    setDishToDelete(idItem);
    setAlertVisible(true);
  };

  //const ocultaItemDelMenu = async (item, ocultaItem) => {
  //let respuesta;
  // console.log("item:", item);
  // console.log("ocultaItem:", ocultaItem);
  //respuesta = await ocultaItemOnMenu(item, ocultaItem);
  //if (respuesta) {
  //await loadDishes(); // Vuelve a cargar los platillos desde la API
  //}
  //};

  const handleConfirmDelete = () => {
    if (dishToDelete) {
      handleDeleteDish(dishToDelete);
      setAlertVisible(false); // Cerrar el modal de confirmación
      setDishToDelete(null); // Limpiar el estado
    }
  };

  const handleCancelDelete = () => {
    setAlertVisible(false);
    setDishToDelete(null); // Limpiar el estado en caso de cancelación
  };

  useEffect(() => {
    const loadBranches = async () => {
      try {
        const loadedBranches = await getSucursales(
          localStorage.getItem("userid"),
        );
        setBranches(loadedBranches);
        console.log(branches);
      } catch (error) {
        console.error("Error al cargar las sucursales:", error);
      }
    };

    loadBranches();
  }, [userId]);
  const handleSucursalChange = (sucursalId) => {
    setFormValues((prevValues) => {
      const newDisponibilidadSucursales =
        prevValues.disponibilidadSucursales.includes(sucursalId)
          ? prevValues.disponibilidadSucursales.filter(
              (id) => id !== sucursalId,
            )
          : [...prevValues.disponibilidadSucursales, sucursalId];
      console.log(
        "Updated disponibilidadSucursales:",
        newDisponibilidadSucursales,
      );
      return {
        ...prevValues,
        disponibilidadSucursales: newDisponibilidadSucursales,
      };
    });
  };
  const handleEdit = async (idItem) => {
    //const id = localStorage.getItem("userid");
    //console.log(id);
    //const loadedBranches = await getSucursales(userId);

    let value = "Editar platillo"; // Establecer el valor del botón
    let variant = "received"; // Establecer la variante del botón
    const dishToEdit = dishes.find((dish) => dish._id === idItem);
    console.log("dishToEdit:", dishToEdit);
    if (dishToEdit) {
      setFormValues({
        id: dishToEdit._id, // Asigna el ID para la edición
        name: dishToEdit.nombrePlatillo,
        description: dishToEdit.descripcion,
        price: dishToEdit.precio,
        image: dishToEdit.imgPlatillo, // Mantén la imagen actual
        category: dishToEdit.categoria,
        estadoSucursal: dishToEdit.ocultar,
        disponibilidadSucursales: dishToEdit.disponibilidadSucursales || [], // Asegúrate de que siempre haya un arreglo
      });
      setIsModalOpen(true); // Abre el modal de edición
      setModalButtonValues({ value, variant });
    }
  };

  const openModal = () => {
    let value = "Agregar platillo"; // Establecer el valor del botón
    let variant = "default"; // Variante que indique una acción peligrosa como eliminar
    setIsModalOpen(true);
    setModalButtonValues({ value, variant });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setFormValues({
      name: "",
      description: "",
      price: "",
      image: "",
      category: "",
    });
    setLoadingModal(false); // Resetear el estado del loader al cerrar el modal
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        // Convertir la imagen a WebP usando Canvas
        const webpBase64 = await convertToWebP(file);

        // Actualizar el estado con la imagen en WebP y codificada en Base64
        setFormValues({
          ...formValues,
          image: webpBase64,
        });
      } catch (error) {
        console.error("Error al convertir la imagen:", error);
      }
    }
  };

  // Función para convertir una imagen a WebP y devolverla en formato Base64
  const convertToWebP = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          // Establecer las dimensiones del canvas igual a la imagen
          canvas.width = img.width;
          canvas.height = img.height;

          // Dibujar la imagen en el canvas
          ctx.drawImage(img, 0, 0, img.width, img.height);

          // Exportar el contenido del canvas como WebP en Base64
          const webpBase64 = canvas.toDataURL("image/webp", 0.5); // Calidad de 80%
          resolve(webpBase64);
        };

        img.onerror = () => reject("Error al cargar la imagen");
      };

      reader.onerror = () => reject("Error al leer el archivo");
      reader.readAsDataURL(file);
    });
  };

  const handleEstadoChange = (newEstado) => {
    setFormValues({
      ...formValues,
      estadoSucursal: newEstado,
    });
    console.log("Nuevo estado:", newEstado);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingModal(true); // Iniciar el loader en el modal

    try {
      const {
        name,
        description,
        price,
        image,
        category,
        id,
        estadoSucursal,
        disponibilidadSucursales,
      } = formValues;
      let response;
      if (id) {
        // Si hay un ID, significa que estamos editando
        console.log("estadoSucursal:", estadoSucursal);
        response = await editaItemOnMenu(
          id,
          name,
          description,
          price,
          image,
          category,
          userId,
          estadoSucursal,
          disponibilidadSucursales,
        );
      } else {
        // Si no hay ID, significa que estamos agregando
        response = await NewItemOnMenu(
          name,
          description,
          price,
          image,
          category,
          userId,
          true,
        );
      }

      if (response) {
        await loadDishes(); // Vuelve a cargar los platillos desde la API
        alert(
          id ? "Platillo editado con éxito" : "Platillo agregado con éxito",
        );
      }

      closeModal(); // Cerrar el modal
    } catch (error) {
      console.error("Error al guardar el platillo:", error);
      alert("Error al guardar el platillo. Intenta nuevamente.");
    } finally {
      setLoadingModal(false); // Finaliza el loader en el modal
    }
  };

  return (
    <DashboardLayout>
      <Breadcrumbs items={[{ label: "Menú", link: "/menu" }]} />
      <hr className="solid" />
      {loading ? (
        <div className="loader1">Cargando Menu...</div>
      ) : (
        <div className="dish-list">
          <div className="dish-card">
            <img src="/img/food.jpg" alt="" className="dish-card-image" />
            <div className="dish-card-content">
              <button className="add-btn" onClick={openModal}>
                <i className="fas fa-utensils"></i> &nbsp; Agregar nuevo
                platillo
              </button>
            </div>
          </div>
          {dishes.map((dish) => (
            <DishCard
              key={dish._id}
              name={dish.nombrePlatillo}
              price={dish.precio}
              description={dish.descripcion}
              image={dish.imgPlatillo}
              idItem={dish._id}
              ocultar={dish.ocultar}
              onEdit={handleEdit}
              onDelete={() => handleOpenDeleteModal(dish._id)}
              //  onClick={() => ocultaItemDelMenu(dish._id, dish.ocultar)}
            />
          ))}

          {isModalOpen && (
            /*<div className="modal-overlay" onClick={closeModal}>*/
            <div className="modal-overlay">
              <div
                className="modal-content"
                onClick={(e) => e.stopPropagation()}
              >
                <h2>Agregar nuevo platillo</h2>
                <button className="close-btn" onClick={closeModal}>
                  x
                </button>
                <form className={style.form} onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="name">Nombre del Platillo</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formValues.name}
                      onChange={handleInputChange}
                      required
                      placeholder="Ej. Tacos al pastor"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="description">Descripción</label>
                    <textarea
                      id="description"
                      name="description"
                      value={formValues.description}
                      onChange={handleInputChange}
                      required
                      placeholder="Ej. Deliciosos tacos con carne adobada"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="price">Precio</label>
                    <input
                      type="number"
                      id="price"
                      name="price"
                      value={formValues.price}
                      onChange={handleInputChange}
                      required
                      placeholder="Ej. 100"
                      min="0"
                      step="0.01"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="image">Imagen del Platillo</label>
                    <input
                      type="file"
                      id="image"
                      name="image"
                      onChange={handleImageUpload}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="category">Categoría</label>
                    <select
                      id="category"
                      name="category"
                      value={formValues.category}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">Selecciona una categoría</option>
                      <option value="Entradas">Entradas</option>
                      <option value="Comidas">Comidas</option>
                      <option value="Postres">Postres</option>
                      <option value="Bebidas">Bebidas</option>
                    </select>
                  </div>
                  {formValues.id && (
                    <div className="form-group">
                      <label htmlFor="sucursales">Disponilidad en menu</label>
                      <Sucursal
                        label={"Sucursal principal"}
                        estado={formValues.estadoSucursal}
                        onChange={handleEstadoChange}
                      />
                      <label htmlFor="sucursales">
                        Disponibilidad en sucursales
                      </label>
                      {branches.length > 0 ? (
                        branches.map((branch) => (
                          <div
                            key={branch.idSucursal}
                            className={style.sucursales}
                          >
                            <span
                              htmlFor={branch.nombreSucursal}
                              className={style.check}
                            >
                              {branch.nombreSucursal}
                            </span>
                            <input
                              type="checkbox"
                              checked={formValues.disponibilidadSucursales.includes(
                                branch.idSucursal,
                              )}
                              onChange={() =>
                                handleSucursalChange(branch.idSucursal)
                              }
                            />
                          </div>
                        ))
                      ) : (
                        <p>No hay sucursales disponibles.</p>
                      )}
                    </div>
                  )}
                  <Button
                    type="submit"
                    label={modalButtonValues.value}
                    variant={modalButtonValues.variant}
                    size="large"
                  />
                  {loadingModal && (
                    <div className="loader-overlay">
                      <div className="loader">Cargando...</div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          )}

          {/* Alerta de confirmación */}
          <Alert
            label="¿Estás seguro de que deseas eliminar este platillo del menú?"
            isVisible={isAlertVisible}
          >
            <Button
              label="Confirmar"
              variant="canceled"
              size="medium"
              onClick={handleConfirmDelete}
            />
            <Button
              label="Cancelar"
              variant="default"
              size="medium"
              onClick={handleCancelDelete}
            />
          </Alert>
        </div>
      )}
    </DashboardLayout>
  );
};

export default Menu;
