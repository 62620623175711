import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs";
import DashboardLayout from "../layouts/DashboardLayout";
import PaymentPage from "./PaymentPage";
import PaymentStatus from "../components/PaymentStatus";
import styles from "../css/perfil.module.css";

const Perfil = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);
  //customerId="cus_Rj740y8ayRdvBr"
  useEffect(() => {
    const storedUserId = localStorage.getItem("userid");
    //  console.log("userId:", userId);
    if (!storedUserId) {
      navigate("/login"); // Redirigir si no hay sesión
    } else {
      setUserId(storedUserId);
    }
  }, [navigate]);

  return (
    <DashboardLayout>
      <Breadcrumbs items={[{ label: "Perfil", link: "/perfil" }]} />
      <hr className="solid" />
      <br />
      <div className={styles.con}>
        <PaymentStatus userId={userId} />
        <PaymentPage />
      </div>
    </DashboardLayout>
  );
};

export default Perfil;
