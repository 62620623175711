import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
// import './DashboardLayout.css'; // CSS opcional para el layout

const DashboardLayout = ({ children, isModalOpen }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div>
      <button className="hamburger" onClick={toggleMenu}>
        ☰
      </button>

      {/* Sidebar con estilos desactivados si el modal está abierto */}
      <Sidebar
        menuOpen={menuOpen}
        toggleMenu={toggleMenu}
        style={{
          pointerEvents: isModalOpen ? "none" : "auto",
          opacity: isModalOpen ? 0.5 : 1,
        }}
      />

      {/* Topbar */}
      <Topbar />

      {/* Contenido dinámico */}
      <div
        className="main-content"
        style={{ pointerEvents: isModalOpen ? "none" : "auto" }}
      >
        {children} {/* Aquí se mostrarán las vistas parciales */}
      </div>
    </div>
  );
};

export default DashboardLayout;
