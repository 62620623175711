// src/components/ProtectedRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/" />; // Redirige al login si no hay usuario
  }

  return children; // Permite el acceso a los hijos si hay un usuario
};

export default ProtectedRoute;
