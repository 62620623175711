// src/context/AuthContext.js
import React, { createContext, useContext, useState } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const token = localStorage.getItem("token");
    const userid = localStorage.getItem("userid");
    const email = localStorage.getItem("email");
    const nombre = localStorage.getItem("nombre");
    const apellido = localStorage.getItem("apellido");
    const plan = localStorage.getItem("plan");

    return token && userid
      ? { token, email, userid, nombre, apellido, plan }
      : null;
  });

  const login = (token, email, userid, nombre, apellido, plan) => {
    localStorage.setItem("token", token);
    localStorage.setItem("email", email);
    localStorage.setItem("userid", userid);
    localStorage.setItem("nombre", nombre);
    localStorage.setItem("apellido", apellido);
    localStorage.setItem("plan", plan);

    setUser({ token, email, userid, nombre, apellido, plan });
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("userid");
    localStorage.removeItem("nombre");
    localStorage.removeItem("apellido");
    localStorage.removeItem("plan");
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
