import React, { useState, useEffect } from "react";
import styles from "../css/header.module.css";

const Header = () => {
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  useEffect(() => {
    const updateDateTime = () => {
      const now = new Date();

      // Formato de fecha
      const optionsDate = {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
      };
      const formattedDate = now.toLocaleDateString("es-ES", optionsDate);

      // Formato de hora (incluyendo segundos)
      const optionsTime = {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      };
      const formattedTime = now.toLocaleTimeString("es-ES", optionsTime);

      setDate(formattedDate);
      setTime(formattedTime);
    };

    updateDateTime(); // Llamada inicial para mostrar la hora al cargar el componente
    const intervalId = setInterval(updateDateTime, 1000); // Actualiza cada segundo

    return () => clearInterval(intervalId); // Limpia el intervalo al desmontar el componente
  }, []);

  return (
    <div className={styles.headerBar}>
      <div className={styles.headerContent}>
        <div className={styles.saasName}>paLlevar</div>
        <div className={styles.restaurantName}>Restaurante `RAGA`</div>
      </div>
      <div className={styles.dateTime}>
        <span className={styles.date}>{date}</span>
        <span className={styles.time}>{time}</span>
      </div>
    </div>
  );
};

export default Header;
