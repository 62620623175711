import React from "react";
import style from "../css/adminPanel.module.css";

const Breadcrumbs = ({ items, showKitchenLink }) => {
  return (
    <nav className="navegation-breadcrumbs" aria-label="breadcrumb">
      <ul className="breadcrumb">
        {items.map((item, index) => (
          <li
            key={index}
            className={index === items.length - 1 ? "active" : ""}
          >
            {index !== items.length - 1 ? (
              <a href={item.link}>{item.label}</a>
            ) : (
              <span>{item.label}</span>
            )}
          </li>
        ))}
        {showKitchenLink && (
          <li className={style.linkComanda}>
            <a
              href="https://lacomanda.espallevar.com/"
              target="_blanks"
              title="Revisa tu comanda digital"
            >
              https://lacomanda.espallevar.com/
            </a>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Breadcrumbs;
