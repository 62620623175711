import React, { useState } from "react";
import { createUser } from "../services/authService";
import styles from "../css/signup.module.css";
import LoadingDots from "./LoadingDots";

const NewUser = () => {
  const [username, setUsername] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess(false);
    setIsLoading(true);

    if (password.length < 8) {
      setError("La contraseña debe tener al menos 8 caracteres.");
      setIsLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      setError("Las contraseñas no coinciden.");
      setIsLoading(false);
      return;
    }

    if (!validateEmail(email)) {
      setError("El formato del correo electrónico es inválido.");
      setIsLoading(false);
      return;
    }

    try {
      const response = await createUser(username, lastname, email, password);
      console.log(response);
      if (response.message === "false") {
        setError("Error al crear el usuario");
      } else {
        setSuccess(true);
        setUsername("");
        setLastname("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      {/* Columna izquierda */}
      <div className={styles.leftSide}>
        <div className={styles.infoContainer}>
          <img
            src="/img/logo_fondo_transparente.png"
            alt="Pallevar Logo"
            className={styles.logo}
          />
          <h1 className={styles.mainTitle}>
            Experimenta nuestra solución completa de pedidos y administración,
            ¡gratis por 3 meses!
          </h1>
          <h2 className={styles.subTitle}>Con Pallevar, obtienes:</h2>
          <ul className={styles.benefitsList}>
            <li>
              Un panel administrativo intuitivo para gestionar tus órdenes.
            </li>
            <li>Menús interactivos personalizables para tu negocio.</li>
            <li>
              Notificaciones en tiempo real para tu equipo de cocina y staff.
            </li>
            <li>
              Estadísticas y reportes para el crecimiento de tu restaurante.
            </li>
            <li>
              Integraciones con sistemas de pago y facturación electrónica.
            </li>
            <li>Soporte técnico y guías paso a paso.</li>
            <li>Automatización de tareas y organización de pedidos.</li>
          </ul>
          <p className={styles.contact}>
            <strong>¿Tienes dudas?</strong> Contáctanos y habla con uno de
            nuestros especialistas:
            <a href="tel:+526648776301" className={styles.phone}>
              +52 (664) 877-6301
            </a>
          </p>
        </div>
      </div>

      {/* Columna derecha */}
      <div className={styles.rightSide}>
        {success ? (
          <div className={styles.successMessage}>
            <h2>¡Gracias por registrarte en Pallevar!</h2>
            <p>
              Te hemos enviado un correo electrónico de bienvenida a {email}
            </p>
            <a href="/">Haz clic aquí para iniciar sesión</a>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className={styles.form}>
            <h1>¡Regístrate!</h1>
            <div className={styles.formGroup}>
              <label>Nombre:</label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label>Apellido:</label>
              <input
                type="text"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label>Correo electrónico:</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label>Contraseña:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label>Confirmar contraseña:</label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className={styles.submitButton}
              disabled={isLoading}
            >
              {isLoading ? <LoadingDots /> : "Crear Usuario"}
            </button>
            {error && <p className={styles.error}>{error}</p>}
            <p className={styles.link}>
              ¿Ya tienes una cuenta? <a href="/">Inicia sesión</a>
            </p>
          </form>
        )}
      </div>
    </div>
  );
};

export default NewUser;
