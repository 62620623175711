import React, { useEffect, useState } from "react";
import styles from "../css/perfil.module.css";
import { checkPaymentStatus } from "../services/authService";
import LoadingDots from "./LoadingDots";

const PaymentStatus = ({ userId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);

  useEffect(() => {
    const checkPaymentMethod = async () => {
      if (!userId) {
        console.warn("userId is null, skipping checkPaymentStatus call");
        setIsLoading(false);
        return;
      }

      try {
        const response = await checkPaymentStatus(userId);
        const data = response.data;

        // Verificamos si hay método de pago
        if (data.hasPaymentMethod) {
          setHasPaymentMethod(true);
          setPaymentMethod(data.paymentMethod);
        } else {
          setHasPaymentMethod(false);
        }
      } catch (error) {
        console.error("Error al verificar el método de pago:", error);
        setHasPaymentMethod(false); // Si ocurre un error, también se considera como sin método de pago
      } finally {
        setIsLoading(false); // Cambiar a false solo cuando la respuesta llega
      }
    };

    checkPaymentMethod();
  }, [userId]);

  if (isLoading) {
    return (
      <div className={styles.container}>
        <h2>Método de Pago</h2>
        <LoadingDots />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <h2>Método de Pago</h2>
      {hasPaymentMethod ? (
        <div>
          <p>Nombre del titular: {paymentMethod.billing_details.name}</p>
          <p>
            Método de pago registrado: **** **** **** {paymentMethod.card.last4}
          </p>
          <p>
            Vencimiento: {paymentMethod.card.exp_month}/
            {paymentMethod.card.exp_year}
          </p>
        </div>
      ) : (
        <p>No hay un método de pago registrado.</p>
      )}
    </div>
  );
};

export default PaymentStatus;
