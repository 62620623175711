import axios from "axios";
import CryptoJS from "crypto-js";
const baseURL = process.env.REACT_APP_API_URL;
//const baseURL = "http://localhost:5000";
const csrfToken = process.env.REACT_APP_API_TOKEN;
export const login = async (email, password) => {
  try {
    const hPassword = CryptoJS.SHA256(password).toString();
    const userData = { email, hPassword };
    const response = await axios.post(`${baseURL}/login/`, userData, {
      mode: "cors",
      headers: {
        "X-CSRFToken": csrfToken,
        "Content-Type": "application/json",
        Authorization: "BeareraccessToken",
        Accept: "application/json",
      },
    });
    return response.data;
  } catch (e) {
    if (e.response) {
      throw new Error(
        e.response.data.message || "Error al iniciar sesion de usuario",
      );
    } else {
      throw new Error("Error de red o problema con la solicitud");
    }
  }
};

export const createUser = async (username, lastname, email, password) => {
  const name = username;
  const lname = lastname;
  const hPassword = CryptoJS.SHA256(password).toString();
  const userData = { name, lname, email, hPassword };
  try {
    const response = await axios.post(`${baseURL}/registro/`, userData, {
      mode: "cors",
      headers: {
        "X-CSRFToken": csrfToken,
        "Content-Type": "application/json",
        Authorization: "BeareraccessToken",
        Accept: "application/json",
      },
    });

    return response.data;
  } catch (error) {
    // Verifica si hay respuesta de la API
    if (error.response) {
      throw new Error(
        error.response.data.message || "Error al crear el usuario",
      );
    } else {
      throw new Error("Error de red o problema con la solicitud");
    }
  }
};

export const isEmailTaken = async (email) => {
  try {
    const response = await axios.get(`${baseURL}/registro`, {
      params: { email },
    });
    return response.data; // Suponiendo que la respuesta será un booleano
  } catch (error) {
    console.error("Error al verificar el email:", error);
    throw error; // Propagar el error para manejarlo en el componente
  }
};

export const NewItemOnMenu = async (
  name,
  description,
  price,
  image,
  category,
  userId,
  ocultaItem,
) => {
  try {
    const userData = {
      name,
      description,
      price,
      image,
      category,
      userId,
      ocultaItem,
    };
    const response = await axios.post(`${baseURL}/item-menu/`, userData, {
      mode: "cors",
      headers: {
        "X-CSRFToken": csrfToken,
        "Content-Type": "application/json",
        Authorization: "BeareraccessToken",
        Accept: "application/json",
      },
    });

    return response.data;
  } catch (e) {
    if (e.response) {
      throw new Error(
        e.response.data.message || "Error al iniciar sesion de usuario",
      );
    } else {
      throw new Error("Error de red o problema con la solicitud");
    }
  }
};

export const editaItemOnMenu = async (
  id,
  name,
  description,
  price,
  image,
  category,
  userId,
  ocultaItem,
  disponibilidadSucursales,
) => {
  console.log(id, name, description, price, category);
  console.log("ocultar:", ocultaItem);
  console.log("disponibilidadSucursales:", disponibilidadSucursales);
  try {
    const response = await axios.put(
      `${baseURL}/item-menu`,
      {
        id,
        name,
        description,
        price,
        image,
        category,
        ocultaItem,
        disponibilidadSucursales,
      },
      {
        mode: "cors",
        headers: {
          "X-CSRFToken": csrfToken,
          "Content-Type": "application/json",
          Authorization: "Bearer accessToken",
          Accept: "application/json",
        },
      },
    );
    if (response) {
      return response;
    }

    if (response.status === 404) {
      console.log("El producto no se actualizó");
      return null;
    }
  } catch (error) {
    console.error("Error al actualizar el registro:", error);
    throw new Error("Error al actualizar el registro:", error);
  }
};

export const getMenuItems = async (userId) => {
  const id = userId;

  try {
    const response = await axios.get(`${baseURL}/item-menu/${id}`, {
      headers: {
        "X-CSRFToken": csrfToken,
        "Content-Type": "application/json",
        Authorization: `Bearer X`,
        Accept: "application/json",
      },
    });
    if (response) {
      return response.data;
    }
    if (response.status === 404) {
      console.log("El producto no fue encontrado para el usuario:", userId);
      return null;
    }
  } catch (error) {
    console.error("Error al obtener la información del usuario:", error);
    throw new Error("Error al obtener la información del usuario:", error);
  }
};

export const deleteMenuItem = async (idItem) => {
  const id = idItem;

  try {
    const response = await axios.delete(`${baseURL}/item-menu/${id}`, {
      headers: {
        "X-CSRFToken": csrfToken,
        "Content-Type": "application/json",
        Authorization: `Bearer X`,
        Accept: "application/json",
      },
    });

    return response.data; // Axios ya devuelve el resultado parseado
  } catch (error) {
    console.error("Error eliminando el platillo:", error);
    return { success: false }; // Manejar el error como en el ejemplo original
  }
};

export const dashboardInfo = async (userId) => {
  const id = userId;
  // console.log("idRestaurante:", id);
  try {
    const response = await axios.get(`${baseURL}/dashboardMenu/${id}`, {
      headers: {
        "X-CSRFToken": csrfToken,
        "Content-Type": "application/json",
        Authorization: `Bearer X`,
        Accept: "application/json",
      },
    });

    // console.log("response:", response);
    //  if (response) {
    return response;
    //   }
    /*
      if (response.status === 404){
          console.log('El producto no fue encontrado para el usuario:', userId);
          return null;
      }
          */
  } catch (error) {
    console.error("Error al obtener la información del usuario:", error);
    throw new Error("Error al obtener la información del usuario:", error);
  }
};

export const updateuserInfo = async (
  userId,
  restaurantName,
  restaurantType,
) => {
  try {
    const userData = { userId, restaurantName, restaurantType };
    const response = await axios.put(`${baseURL}/registro`, userData, {
      mode: "cors",
      headers: {
        "X-CSRFToken": csrfToken,
        "Content-Type": "application/json",
        Authorization: "BeareraccessToken",
        Accept: "application/json",
      },
    });

    if (response) {
      return response;
    }

    if (response.status === 404) {
      console.log("El producto no se actualizo");
      return null;
    }
  } catch (error) {
    console.error("Error al actualizar el registro:", error);
    throw new Error("Error al actualizar el registro:", error);
  }
};

export const obtieneOrdenes = async (restaurantId) => {
  // console.log("restaurantId:", restaurantId);
  try {
    const response = await axios.get(
      `${baseURL}/procesaOrden/${restaurantId}`,
      {
        headers: {
          "X-CSRFToken": csrfToken,
          "Content-Type": "application/json",
          Authorization: `Bearer X`,
          Accept: "application/json",
        },
      },
    );
    if (response) {
      return response.data.orders;
    }
    if (response.status === 404) {
      console.log(
        "El producto no fue encontrado para el usuario:",
        restaurantId,
      );
      return null;
    }
  } catch (error) {
    console.error("Error al obtener la información del usuario:", error);
    throw new Error("Error al obtener la información del usuario:", error);
  }
};

export const saveMenuColor = async (restaurantId, colorMenu) => {
  // console.log(restaurantId, colorMenu);
  try {
    const userData = { restaurantId, colorMenu };
    const response = await axios.post(`${baseURL}/restsettings/`, userData, {
      mode: "cors",
      headers: {
        "X-CSRFToken": csrfToken,
        "Content-Type": "application/json",
        Authorization: "BeareraccessToken",
        Accept: "application/json",
      },
    });
    return response;
  } catch (e) {
    if (e.response) {
      throw new Error(
        e.response.data.message || "Error al iniciar sesion de usuario",
      );
    } else {
      throw new Error("Error de red o problema con la solicitud");
    }
  }
};

export const getMenuColor = async (restaurantId) => {
  try {
    const response = await axios.get(
      `${baseURL}/restsettings/${restaurantId}`,
      {
        headers: {
          "X-CSRFToken": csrfToken,
          "Content-Type": "application/json",
          Authorization: `Bearer X`, // Verifica que el token de autorización sea correcto
          Accept: "application/json",
        },
      },
    );

    // Verifica si la respuesta tiene un estado 404
    if (response.status === 404) {
      console.log("El color no fue encontrado para el usuario:", restaurantId);
      return null;
    }

    // Si la respuesta fue exitosa, regresa el valor de color
    if (response && response.data) {
      return response.data;
    }

    // Si la respuesta no tiene lo que esperas
    console.log("No se encontró color en la respuesta");
    return null;
  } catch (error) {
    console.error("Error al obtener la información del usuario:", error);
    throw new Error("Error al obtener la información del usuario:", error);
  }
};

export const stripeOnboard = async (userId) => {
  // console.log("userId:", userId);
  try {
    const userData = { userId };
    const response = await axios.post(`${baseURL}/stripe/onboard`, userData, {
      mode: "cors",
      headers: {
        "X-CSRFToken": csrfToken,
        "Content-Type": "application/json",
        Authorization: "BeareraccessToken",
        Accept: "application/json",
      },
    });
    // console.log(response);
    return response;
  } catch (e) {
    if (e.response) {
      throw new Error(e.response.data.message || "Error");
    } else {
      throw new Error("Error de red o problema con la solicitud");
    }
  }
};

export const status = async (userId) => {
  try {
    const response = await axios.get(`${baseURL}/stripe/onboard/${userId}`, {
      headers: {
        "X-CSRFToken": csrfToken,
        "Content-Type": "application/json",
        Authorization: `Bearer X`, // Verifica que el token de autorización sea correcto
        Accept: "application/json",
      },
    });

    // console.log("response-1:", response.data);
    return response; // Suponiendo que la respuesta será un booleano
  } catch (error) {
    console.error("Error al verificar el email:", error);
    throw error; // Propagar el error para manejarlo en el componente
  }
};

export const ocultaItemOnMenu = async (id, ocultaItem) => {
  // console.log(id, ocultaItem);

  if (ocultaItem === "true") {
    ocultaItem = "false";
  } else {
    ocultaItem = "true";
  }

  try {
    const response = await axios.put(
      `${baseURL}/item-menu`,
      {
        id,
        ocultaItem,
      },
      {
        mode: "cors",
        headers: {
          "X-CSRFToken": csrfToken,
          "Content-Type": "application/json",
          Authorization: "Bearer accessToken",
          Accept: "application/json",
        },
      },
    );

    if (response) {
      return response;
    }

    if (response.status === 404) {
      console.log("El producto no se actualizó");
      return null;
    }
  } catch (error) {
    console.error("Error al actualizar el registro:", error);
    throw new Error("Error al actualizar el registro:", error);
  }
};

export const createUserPersonal = async (
  name,
  email,
  password,
  restaurante,
) => {
  //const name = username;

  const hPassword = CryptoJS.SHA256(password).toString();
  const userData = { name, email, hPassword, restaurante };

  try {
    // console.log(userData);

    const response = await axios.post(`${baseURL}/personal/`, userData, {
      mode: "cors",
      headers: {
        "X-CSRFToken": csrfToken,
        "Content-Type": "application/json",
        Authorization: "BeareraccessToken",
        Accept: "application/json",
      },
    });

    return response.data;
  } catch (error) {
    // Verifica si hay respuesta de la API
    if (error.response) {
      throw new Error(
        error.response.data.message || "Error al crear el usuario",
      );
    } else {
      throw new Error("Error de red o problema con la solicitud");
    }
  }
};

export const getPersonal = async (id) => {
  try {
    const response = await axios.get(`${baseURL}/personal/${id}`, {
      headers: {
        "X-CSRFToken": csrfToken,
        "Content-Type": "application/json",
        Authorization: `Bearer X`,
        Accept: "application/json",
      },
    });

    // Verifica si la respuesta tiene un estado 404
    if (response.status === 404) {
      console.log("El color no fue encontrado para el usuario:", id);
      return null;
    }

    // Si la respuesta fue exitosa, regresa el valor de color
    if (response && response.data) {
      return response.data;
    }

    // Si la respuesta no tiene lo que esperas
    console.log("No se encontró color en la respuesta");
    return null;
  } catch (error) {
    console.error("Error al obtener la información del usuario:", error);
    throw new Error("Error al obtener la información del usuario:", error);
  }
};

export const deletePersonal = async (idItem) => {
  const id = idItem;

  try {
    const response = await axios.delete(`${baseURL}/personal/${id}`, {
      headers: {
        "X-CSRFToken": csrfToken,
        "Content-Type": "application/json",
        Authorization: `Bearer X`,
        Accept: "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error eliminando el platillo:", error);
    return { success: false };
  }
};

export const restInfo = async (id) => {
  // console.log(id);
  try {
    const response = await axios.get(`${baseURL}/rest-info/${id}`, {
      headers: {
        "X-CSRFToken": csrfToken,
        "Content-Type": "application/json",
        Authorization: `Bearer X`,
        Accept: "application/json",
      },
    });

    // console.log(response);
    // Verifica si la respuesta tiene un estado 404
    if (response.status === 404) {
      console.log("El color no fue encontrado para el usuario:", id);
      return null;
    }
    if (response) {
      return response;
    }

    return null;
  } catch (error) {
    console.error("Error al obtener la información del usuario:", error);
    throw new Error("Error al obtener la información del usuario:", error);
  }
};

export const updateRestInfo = async (
  userId,
  nombreRestaurante,
  direccion,
  numeroTelefono,
  logo,
) => {
  // console.log("userId:", userId);
  // console.log("nombreRestaurante:", nombreRestaurante);
  // console.log("direccion:", direccion);
  // console.log("numeroTelefono:", numeroTelefono);
  // console.log("logo:", logo);
  const userData = {
    userId,
    nombreRestaurante,
    direccion,
    numeroTelefono,
    logo,
  };
  try {
    const response = await axios.post(`${baseURL}/rest-info`, userData, {
      mode: "cors",
      headers: {
        "X-CSRFToken": csrfToken,
        "Content-Type": "application/json",
        Authorization: "BeareraccessToken",
        Accept: "application/json",
      },
    });
    // console.log(response);
    return response;
  } catch (e) {
    if (e.response) {
      throw new Error(e.response.data.message || "Error");
    } else {
      throw new Error("Error de red o problema con la solicitud");
    }
  }
};

export const resetPasword = async (email) => {
  try {
    //const hPassword = CryptoJS.SHA256(password).toString();
    const userData = { email };
    const response = await axios.post(`${baseURL}/resetPasword/`, userData, {
      mode: "cors",
      headers: {
        "X-CSRFToken": csrfToken,
        "Content-Type": "application/json",
        Authorization: "BeareraccessToken",
        Accept: "application/json",
      },
    });
    return response.data;
  } catch (e) {
    if (e.response) {
      throw new Error(
        e.response.data.message || "Error al iniciar sesion de usuario",
      );
    } else {
      throw new Error("Error de red o problema con la solicitud");
    }
  }
};

export const newPassword = async (password, token) => {
  try {
    password = CryptoJS.SHA256(password).toString();
    const userData = { password, token };
    const response = await axios.put(`${baseURL}/resetPasword/`, userData, {
      mode: "cors",
      headers: {
        "X-CSRFToken": csrfToken,
        "Content-Type": "application/json",
        Authorization: "BeareraccessToken",
        Accept: "application/json",
      },
    });
    // console.log(response.data);
    return response.data;
  } catch (e) {
    if (e.response) {
      throw new Error(
        e.response.data.message || "Error al iniciar sesion de usuario",
      );
    } else {
      throw new Error("Error de red o problema con la solicitud");
    }
  }
};

export const checkPaymentStatus = async (userId) => {
  userId;
  try {
    const response = await axios.get(
      `${baseURL}/stripe/paymentstatus/${userId}`,
      {
        headers: {
          "X-CSRFToken": csrfToken,
          "Content-Type": "application/json",
          Authorization: `Bearer X`,
          Accept: "application/json",
        },
      },
    );

    if (response.status === 404) {
      console.log("El color no fue encontrado para el usuario:", userId);
      return null;
    }
    if (response) {
      return response;
    }

    return null;
  } catch (error) {
    console.error("Error al obtener la información del usuario:", error);
    throw new Error("Error al obtener la información del usuario:", error);
  }
};

export const updatePaymentMethod = async (idUser, paymentMethod) => {
  try {
    const userData = { idUser, paymentMethod };
    console.log("userData:", userData);
    const response = await axios.post(
      `${baseURL}/stripe/savePaymentMethod/`,
      userData,
      {
        mode: "cors",
        headers: {
          "X-CSRFToken": csrfToken,
          "Content-Type": "application/json",
          Authorization: "BeareraccessToken",
          Accept: "application/json",
        },
      },
    );
    return response.data;
  } catch (e) {
    if (e.response) {
      throw new Error(
        e.response.data.message || "Error al iniciar sesion de usuario",
      );
    } else {
      throw new Error("Error de red o problema con la solicitud");
    }
  }
};
export const obtenerHorario = async (idRestaurante) => {
  try {
    const response = await axios.get(
      `${baseURL}/horario-operacion/${idRestaurante}`,
      {
        headers: {
          "X-CSRFToken": csrfToken,
          "Content-Type": "application/json",
          Authorization: "BeareraccessToken",
          Accept: "application/json",
        },
      },
    );
    if (!response) {
      throw new Error("Error al obtener el horario");
    }
    return response.data;
  } catch (error) {
    console.error("Error fetching schedule:", error);
    throw error;
  }
};
export const horarioDeOperacion = async (idRestaurante, schedule) => {
  try {
    const userData = { idRestaurante, schedule };
    //console.log("userData:", userData);

    const response = await axios.post(
      `${baseURL}/horario-operacion/`,
      userData,
      {
        mode: "cors",
        headers: {
          "X-CSRFToken": csrfToken,
          "Content-Type": "application/json",
          Authorization: "BeareraccessToken",
          Accept: "application/json",
        },
      },
    );
    return response.data;
  } catch (e) {
    if (e.response) {
      throw new Error(
        e.response.data.message || "Error al iniciar sesion de usuario",
      );
    } else {
      throw new Error("Error de red o problema con la solicitud");
    }
  }
};

//Sucursales
export const getSucursales = async (id) => {
  try {
    const response = await axios.get(`${baseURL}/sucursales/${id}`, {
      headers: {
        "X-CSRFToken": csrfToken,
        "Content-Type": "application/json",
        Authorization: "BeareraccessToken",
        Accept: "application/json",
      },
    });
    if (!response) {
      throw new Error("Error al obtener el horario");
    }
    return response.data;
  } catch (error) {
    console.error("Error fetching schedule:", error);
    throw error;
  }
};

export const deleteSucursal = async (idItem) => {
  const id = idItem;

  try {
    const response = await axios.delete(`${baseURL}/sucursales/${id}`, {
      headers: {
        "X-CSRFToken": csrfToken,
        "Content-Type": "application/json",
        Authorization: `Bearer X`,
        Accept: "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error eliminando el platillo:", error);
    return { success: false };
  }
};

export const nuevaSucursal = async (userid, name, telefono, direccion) => {
  const userData = { userid, name, telefono, direccion };
  console.log(userData);
  try {
    // console.log(userData);

    const response = await axios.post(`${baseURL}/sucursales/`, userData, {
      mode: "cors",
      headers: {
        "X-CSRFToken": csrfToken,
        "Content-Type": "application/json",
        Authorization: "BeareraccessToken",
        Accept: "application/json",
      },
    });
    console.log(response);
    return response.data;
  } catch (error) {
    // Verifica si hay respuesta de la API
    if (error.response) {
      throw new Error(
        error.response.data.message || "Error al crear el usuario",
      );
    } else {
      throw new Error("Error de red o problema con la solicitud");
    }
  }
};

export const updateSucursal = async (id, name, telefono, direccion) => {
  try {
    const userData = { id, name, telefono, direccion };
    console.log(userData);
    const response = await axios.put(`${baseURL}/sucursales`, userData, {
      mode: "cors",
      headers: {
        "X-CSRFToken": csrfToken,
        "Content-Type": "application/json",
        Authorization: "BeareraccessToken",
        Accept: "application/json",
      },
    });

    if (response) {
      return response;
    }

    if (response.status === 404) {
      console.log("El producto no se actualizo");
      return null;
    }
  } catch (error) {
    console.error("Error al actualizar el registro:", error);
    throw new Error("Error al actualizar el registro:", error);
  }
};
