// src/components/Alert.js
import React from "react";
import styles from "../css/alert.module.css";

const Alert = ({ label, isVisible, children }) => {
  if (!isVisible) return null; // No muestra nada si no está visible

  return (
    <div className={`${styles.overlay} ${isVisible ? styles.visible : ""}`}>
      <div className={styles.alertBox}>
        <h3 className={styles.title}>Confirmación</h3>
        <p className={styles.message}>{label}</p>
        <div className={styles.buttons}>
          {children} {/* Renderiza los botones aquí */}
        </div>
      </div>
    </div>
  );
};

export default Alert;
