// src/components/LoginButton.js
import React from "react";
import styles from "../css/button.module.css";

const LoginButton = ({
  label,
  variant = "default",
  size = "medium",
  onClick,
}) => {
  const buttonClass = `${styles.Boton} ${styles[variant]} ${styles[size]}`;

  return (
    <button type="submit" className={buttonClass} onClick={onClick}>
      {label}
    </button>
  );
};

export default LoginButton;
