import React, { useState, useEffect } from "react";
import "./AdminPanel.css";
import { useAuth } from "../context/AuthContext";
import { dashboardInfo } from "../services/authService";

const Topbar = () => {
  const { logout } = useAuth();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [restaurantName, setRestaurantName] = useState(
    localStorage.getItem("restaurantName") || "",
  );
  const [copySuccess, setCopySuccess] = useState(false); // State to show copy success message
  const userid = localStorage.getItem("userid");

  useEffect(() => {
    const fetchRestaurantName = async () => {
      try {
        const response = await dashboardInfo(userid);
        // console.log(response.data.restaurante);
        if (response.data.restaurante) {
          setRestaurantName(response.data.restaurante);
          localStorage.setItem("restaurantName", response.data.restaurante); // Save to localStorage for future sessions
        }
      } catch (error) {
        console.error("Error al obtener el nombre del restaurante:", error);
      }
    };

    // Only call the API if not already in localStorage
    if (!restaurantName && userid) {
      fetchRestaurantName();
    }
  }, [userid, restaurantName]);

  const handleLogout = () => {
    logout();
    localStorage.removeItem("restaurantName"); // Clear restaurant name on logout
    window.location.href = "/";
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Function to handle copy to clipboard
  const copyToClipboard = () => {
    const link = `https://espallevar.com/${restaurantName.toLowerCase()}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setCopySuccess(true); // Show success message
        setTimeout(() => setCopySuccess(false), 2000); // Hide after 2 seconds
      })
      .catch((err) => console.error("Error copying text: ", err));
  };

  return (
    <div className="topbar">
      <div className="restaurant-info">
        <span>Tu periodo de prueba terminar el dia:</span> &nbsp;
        <span className="trial-end-date">31/12/2021</span>
      </div>
      <div className="user-info">
        <div className="margen">
          <button
            onClick={copyToClipboard}
            className="copy-button"
            title="Copy link to clipboard"
          >
            <i className="fas fa-copy"></i>
          </button>
          &nbsp;
          <a
            href={`https://espallevar.com/${restaurantName.toLowerCase()}`}
            title={`https://espallevar.com/${restaurantName.toLowerCase()}`}
            target="_blank"
            className="labelText"
            rel="noopener noreferrer"
          >
            https://espallevar.com/{restaurantName.toLowerCase()}{" "}
            <i className="fas fa-external-link-alt"></i>
          </a>
          &nbsp;
          {copySuccess && (
            <span className="copy-success">¡Enlace copiado!</span>
          )}{" "}
          {/* Show success message */}
        </div>

        <div className="dropdown">
          <button onClick={toggleDropdown} className="dropdown-toggle">
            {restaurantName.toUpperCase()} <i className="fas fa-caret-down"></i>
          </button>

          {isDropdownOpen && (
            <div className="dropdown-menu">
              <a
                href="/profile"
                className=""
                aria-label={`Ir a perfil de ${localStorage.getItem("nombre")} ${localStorage.getItem("apellido")}`}
              >
                <i className="fas fa-solid fa-user"></i>&nbsp;
                {localStorage.getItem("nombre")}{" "}
                {localStorage.getItem("apellido")}{" "}
              </a>
              <a href="/planes">
                <i className="fas fa-solid  fa-credit-card"></i>
                &nbsp;Planes
              </a>
              <hr />
              <button className="logout-btn" onClick={handleLogout}>
                <i className="fas fa-sign-out-alt"></i> Cerrar Sesión
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Topbar;
