import React, { useState, useEffect } from "react";
import { resetPasword } from "../services/authService";
import { useNavigate } from "react-router-dom"; // Importa useNavigate
//import { useAuth } from "../context/AuthContext"; // Importa el contexto
import LoadingDots from "./LoadingDots";
import "./styles.css";

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  //const { login: loginUser } = useAuth(); // Desestructura el login del contexto

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const handleLogin = async (event) => {
    event.preventDefault();
    setError("");

    if (!email || !validateEmail(email)) {
      setError("Por favor, ingrese un correo electrónico válido.");
      return;
    }

    setLoading(true);
    try {
      const response = await resetPasword(email);

      if (response) {
        setSuccess(true);
      } else {
        setError("Error en la respuesta del servidor.");
      }
    } catch (error) {
      setError("Error al iniciar sesión. Verifica tus credenciales.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="left-side">
        <img
          src="/img/logo_blanco_fondo_transparente.png"
          alt="Logo"
          className="full-screen-logo"
        />
      </div>

      <div className="right-side login-container">
        <img
          src="/img/pallevar-logo-2.PNG"
          alt="Logo"
          className="small-screen-logo"
        />
        {error && <div className="error-message">{error}</div>}
        {success ? (
          <div className="successMessage">
            <h1>
              Hemos enviado un correo electrónico, revisa tu correo para
              continuar con el restablecimiento de tu contraseña.
            </h1>
          </div>
        ) : (
          <>
            <h1>Restablecer contraseña</h1>
            <form onSubmit={handleLogin}>
              <div className="form-group">
                <label htmlFor="email">Correo Electrónico:</label>
                <input
                  type="email"
                  id="email"
                  placeholder="ejemplo@correo.com"
                  value={email}
                  autoComplete="username"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              <button type="submit" className="login-button" disabled={loading}>
                {loading ? <LoadingDots /> : "Restablecer contraseña"}
              </button>

              <div className="forgot-password">
                <a href="/signup">Crea tu cuenta aqui!</a>
              </div>

              <div className="forgot-password">
                <a href="/">Inicio de Sesión</a>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default PasswordReset;
