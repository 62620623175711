import React from "react";
import styles from "../css/operatingHours.module.css";

const daysOfWeek = [
  { name: "Lunes", value: 1 },
  { name: "Martes", value: 2 },
  { name: "Miércoles", value: 3 },
  { name: "Jueves", value: 4 },
  { name: "Viernes", value: 5 },
  { name: "Sábado", value: 6 },
  { name: "Domingo", value: 7 },
];

const OperatingHours = ({
  daySchedule,
  index,
  handleToggle,
  handleTimeChange,
}) => {
  const dayName = daysOfWeek.find((day) => day.value === daySchedule.day).name;

  return (
    <div className={styles.dayRow}>
      <span className={styles.dayLabel}>{dayName}</span>
      <label className={styles.toggle}>
        <input
          type="checkbox"
          checked={daySchedule.open}
          onChange={() => handleToggle(index)}
        />
        <span>Abierto</span>
      </label>
      <select
        className={styles.timeSelect}
        value={daySchedule.openTime}
        onChange={(e) => handleTimeChange(index, "openTime", e.target.value)}
        disabled={!daySchedule.open}
      >
        {[...Array(24)].map((_, i) => (
          <option key={i} value={`${i.toString().padStart(2, "0")}:00`}>
            {i.toString().padStart(2, "0")}:00
          </option>
        ))}
      </select>
      <span>-</span>
      <select
        className={styles.timeSelect}
        value={daySchedule.closeTime}
        onChange={(e) => handleTimeChange(index, "closeTime", e.target.value)}
        disabled={!daySchedule.open}
      >
        {[...Array(24)].map((_, i) => (
          <option key={i} value={`${i.toString().padStart(2, "0")}:00`}>
            {i.toString().padStart(2, "0")}:00
          </option>
        ))}
      </select>
    </div>
  );
};

export default OperatingHours;
