import React, { useState, useEffect } from "react";
import { login } from "../services/authService";
import { useNavigate } from "react-router-dom"; // Importa useNavigate
import { useAuth } from "../context/AuthContext"; // Importa el contexto
import LoadingDots from "./LoadingDots";
import "./styles.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { login: loginUser } = useAuth(); // Desestructura el login del contexto

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    const token = localStorage.getItem("token"); // Reemplaza 'token' con el nombre que uses para almacenar el token
    if (token) {
      // Si el token existe, redirige al dashboard
      navigate("/dashboard"); // Cambia '/dashboard' por la ruta de tu dashboard
    }
  }, [navigate]); // Cambia history por navigate

  const handleLogin = async (event) => {
    event.preventDefault();
    setError("");

    if (!email || !validateEmail(email)) {
      setError("Por favor, ingrese un correo electrónico válido.");
      return;
    }
    if (!password) {
      setError("Por favor, ingrese su contraseña.");
      return;
    }

    setLoading(true);
    try {
      const response = await login(email, password);
      //console.log("login-response", response);
      if (response.email && response.token) {
        loginUser(
          response.token,
          response.email,
          response.userid,
          response.nombre,
          response.apellido,
          response.plan,
        ); // Usa el contexto para iniciar sesión

        navigate("/dashboard"); // Redirige al dashboard
      } else {
        setError("Error en la respuesta del servidor.");
      }
    } catch (error) {
      setError("Error al iniciar sesión. Verifica tus credenciales.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="left-side">
        <img
          src="/img/logo_blanco_fondo_transparente.png"
          alt="Logo"
          className="full-screen-logo"
        />
      </div>

      <div className="right-side login-container">
        <img
          src="/img/pallevar-logo-2.PNG"
          alt="Logo"
          className="small-screen-logo"
        />
        <h1>Inicio de Sesión</h1>

        {error && <div className="error-message">{error}</div>}

        <form onSubmit={handleLogin}>
          <div className="form-group">
            <label htmlFor="email">Correo Electrónico:</label>
            <input
              type="email"
              id="email"
              placeholder="ejemplo@correo.com"
              value={email}
              autoComplete="username"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="password">Contraseña:</label>
            <input
              type="password"
              id="password"
              placeholder="Contraseña"
              value={password}
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>

          <button type="submit" className="login-button" disabled={loading}>
            {loading ? <LoadingDots /> : "Iniciar Sesión"}
          </button>

          <div className="forgot-password">
            <a href="/signup">Crea tu cuenta aqui!</a>
          </div>

          <div className="forgot-password">
            <a href="/password-reset">¿Olvidaste tu contraseña?</a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
