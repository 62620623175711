import { useState, useEffect } from "react";
import styles from "../css/operatingHours.module.css";
import OperatingHours from "./OperatingHours";
import { horarioDeOperacion, obtenerHorario } from "../services/authService";

const daysOfWeek = [
  { name: "Lunes", value: 1 },
  { name: "Martes", value: 2 },
  { name: "Miércoles", value: 3 },
  { name: "Jueves", value: 4 },
  { name: "Viernes", value: 5 },
  { name: "Sábado", value: 6 },
  { name: "Domingo", value: 7 },
];

const defaultSchedule = daysOfWeek.map((day) => ({
  day: day.value,
  open: day.value !== 7, // Domingo cerrado por defecto
  openTime: "08:00",
  closeTime: "18:00",
}));

export default function WorkSchedule() {
  const [schedule, setSchedule] = useState(defaultSchedule);

  useEffect(() => {
    const fetchSchedule = async () => {
      try {
        const restaurantId = localStorage.getItem("userid");
        const response = await obtenerHorario(restaurantId);
        console.log("REsponse:", response);
        if (response && response.schedule) {
          const fetchedSchedule = daysOfWeek.map((day) => ({
            day: day.value,
            open: response.schedule[day.value] !== null,
            openTime: response.schedule[day.value]?.open || "",
            closeTime: response.schedule[day.value]?.close || "",
          }));
          console.log("Fetched schedule:", fetchedSchedule);
          setSchedule(fetchedSchedule);
        }
      } catch (error) {
        console.error("Error fetching schedule:", error);
      }
    };

    fetchSchedule();
  }, []);

  const handleToggle = (index) => {
    const newSchedule = [...schedule];
    newSchedule[index].open = !newSchedule[index].open;
    setSchedule(newSchedule);
  };

  const handleTimeChange = (index, type, value) => {
    const newSchedule = [...schedule];
    newSchedule[index][type] = value;
    setSchedule(newSchedule);
  };

  const transformSchedule = (schedule) => {
    const transformed = {};
    schedule.forEach(({ day, open, openTime, closeTime }) => {
      transformed[day] = open ? { open: openTime, close: closeTime } : null;
    });
    return transformed;
  };

  const saveSchedule = async () => {
    try {
      const restaurantId = localStorage.getItem("userid");
      const transformedSchedule = transformSchedule(schedule);
      const response = await horarioDeOperacion(
        restaurantId,
        transformedSchedule,
      );
      console.log(response);
      alert("Horario guardado con éxito");
    } catch (error) {
      console.error(error);
      alert("Hubo un error al guardar el horario");
    }
  };

  return (
    <div>
      <h2>Horario de Operación</h2>
      {schedule.map((daySchedule, index) => (
        <OperatingHours
          key={index}
          daySchedule={daySchedule}
          index={index}
          handleToggle={handleToggle}
          handleTimeChange={handleTimeChange}
        />
      ))}
      <button className={styles.saveBtn} onClick={saveSchedule}>
        Guardar Horario
      </button>
    </div>
  );
}
