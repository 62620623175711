import React, { useState, useEffect } from "react";
import Button from "./Button";
import Alert from "../components/Alert";
import styles from "../css/modal.module.css";

import {
  getSucursales,
  nuevaSucursal,
  deleteSucursal,
  updateSucursal,
} from "../services/authService";

const Sucursales = ({ idUsuario, tipoPlan }) => {
  //console.log("idUsuario:", idUsuario, "tipoPlan:", tipoPlan);
  const [sucursales, setSucursales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAlertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState(""); // Estado para el mensaje de la alerta
  const [isDeleteAlertVisible, setDeleteAlertVisible] = useState(false); // Alerta de eliminación
  const [personalDelete, setPersonalDelete] = useState(null);
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [selectedSucursal, setSelectedSucursal] = useState(null);

  const [newSucursal, setnewSucursal] = useState({
    sucursalName: "",
    telefono: "",
    direccion: "",
  });

  const handleAddSucursal = async (e) => {
    e.preventDefault();
    //console.log("Agregando nueva sucursal:", newSucursal, tipoPlan);
    // Verificar si el plan es "básico" y si ya hay 2 usuarios
    if (tipoPlan === "basico" && sucursales.length >= 2) {
      setAlertMessage(
        "Has alcanzado el límite de sucursales para el plan básico. Por favor, actualiza tu plan.",
      );
      setAlertVisible(true); // Mostrar la alerta
      return; // Detener la ejecución
    }

    const restaurantId = localStorage.getItem("userid");

    try {
      // Crear nueva sucursal en el backend
      const sucursalCreated = await nuevaSucursal(
        restaurantId,
        newSucursal.sucursalName,
        newSucursal.telefono,
        newSucursal.direccion,
      );

      if (sucursalCreated) {
        //console.log("Sucursal creada:", sucursalCreated);
        // Actualizar la lista de usuarios con la nueva sucursal
        setSucursales([
          ...sucursales,
          {
            nombreSucursal: newSucursal.sucursalName,
            telefono: newSucursal.telefono,
            direccion: newSucursal.direccion,
          },
        ]);
        // Limpiar el formulario
        setnewSucursal({ sucursalName: "", telefono: "", direccion: "" });
      }
    } catch (error) {
      console.error("Error al agregar el usuario:", error);
      setAlertMessage(
        "Ocurrió un error al agregar el usuario. Intenta nuevamente.",
      );
      setAlertVisible(true); // Mostrar la alerta en caso de error
    }
  };

  const handleCloseAlert = () => {
    setAlertVisible(false); // Cerrar la alerta de límite de usuarios
    setAlertMessage(""); // Limpiar el mensaje
  };

  const handleEditSucursal = (sucursal) => {
    setSelectedSucursal(sucursal);
    setEditModalVisible(true);
  };

  const handleCloseEditModal = () => {
    setEditModalVisible(false);
    setSelectedSucursal(null);
  };

  const handleConfirmUpdate = async () => {
    if (!selectedSucursal) return;
    try {
      await updateSucursal(
        selectedSucursal.idSucursal,
        selectedSucursal.nombreSucursal,
        selectedSucursal.telefono,
        selectedSucursal.direccion,
      );
      setEditModalVisible(false);
      getSucursalesUser();
    } catch (error) {
      console.error("Error al actualizar la sucursal:", error);
    }
  };

  const handleOpenDeleteModal = (idItem) => {
    setPersonalDelete(idItem);
    setDeleteAlertVisible(true); // Mostrar la alerta de eliminación
  };

  const handleConfirmDelete = () => {
    if (personalDelete) {
      handledeleteSucursal(personalDelete);
      setDeleteAlertVisible(false); // Cerrar la alerta de confirmación
      setPersonalDelete(null); // Limpiar el estado
    }
  };

  const handleCancelDelete = () => {
    setDeleteAlertVisible(false); // Cerrar la alerta de confirmación
    setPersonalDelete(null); // Limpiar el estado en caso de cancelación
  };

  const handledeleteSucursal = async (idItem) => {
    //console.log("Eliminando usuario con ID:", idItem);
    try {
      const response = await deleteSucursal(idItem);
      if (response) {
        await getSucursalesUser();
      }
    } catch (error) {
      console.error("Error al eliminar el usuario:", error);
      setAlertMessage("Error al eliminar el usuario. Intenta nuevamente.");
      setAlertVisible(true);
    }
  };

  const getSucursalesUser = async () => {
    try {
      const restaurantId = localStorage.getItem("userid");
      const sucursalesData = await getSucursales(restaurantId);
      //console.log(sucursalesData);
      if (sucursalesData) {
        setSucursales(sucursalesData);
      }
    } catch (error) {
      console.error("Error al obtener los usuarios:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSucursalesUser();
  }, [idUsuario, tipoPlan]);

  return (
    <div>
      <h3>Sucursales</h3>
      <table border="1" style={{ width: "100%", marginBottom: "20px" }}>
        <thead>
          <tr>
            <th>Sucursales</th>
            <td colSpan="2"></td>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="2" style={{ textAlign: "center" }}>
                Cargando el personal...
              </td>
            </tr>
          ) : sucursales.length > 0 ? (
            sucursales.map((user, index) => (
              <tr key={index}>
                <td>{user.nombreSucursal}</td>
                <td>
                  <Button
                    label={<i className="fas fa-edit"></i>}
                    variant="received"
                    size="smaller"
                    onClick={() => handleEditSucursal(user)}
                  />
                </td>
                <td>
                  <Button
                    label={<i className="fas fa-trash"></i>}
                    variant="canceled"
                    size="smaller"
                    onClick={() => handleOpenDeleteModal(user.idSucursal)}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2" style={{ textAlign: "center" }}>
                No hay usuarios disponibles.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Formulario para agregar un nuevo usuario */}
      <div>
        <h4>Agregar nueva sucursal</h4>
        <form onSubmit={handleAddSucursal}>
          <div>
            <label>Nombre de la sucursal</label>
            <input
              type="text"
              placeholder="Sucursal - Insurgentes"
              value={newSucursal.sucursalName}
              onChange={(e) =>
                setnewSucursal({ ...newSucursal, sucursalName: e.target.value })
              }
              required
            />
          </div>

          <div>
            <label>Numero de telefono</label>
            <input
              type="tel"
              placeholder="664-454-4678"
              autoComplete="Telefono"
              maxLength="10"
              value={newSucursal.telefono}
              onChange={(e) =>
                setnewSucursal({ ...newSucursal, telefono: e.target.value })
              }
              required
            />
          </div>

          <div>
            <label>Direccion sucursal</label>
            <input
              type="text"
              placeholder="Las huertas 123, Col. Los Pinos"
              autoComplete="direccion"
              value={newSucursal.direccion}
              onChange={(e) =>
                setnewSucursal({ ...newSucursal, direccion: e.target.value })
              }
              required
            />
          </div>

          <div>
            <Button label="Agregar" variant="default" size="medium" />
          </div>
        </form>
      </div>

      {/* Alerta para límite de usuarios */}
      <Alert isVisible={isAlertVisible} label={alertMessage}>
        <Button
          label="Cerrar"
          variant="default"
          size="large"
          onClick={handleCloseAlert}
        />
      </Alert>

      {/* Alerta de confirmación de eliminación */}
      <Alert
        isVisible={isDeleteAlertVisible}
        label="Estás seguro de eliminar este usuario?"
      >
        <Button
          label="Confirmar"
          variant="canceled"
          size="medium"
          onClick={handleConfirmDelete}
        />
        <Button
          label="Cancelar"
          variant="default"
          size="medium"
          onClick={handleCancelDelete}
        />
      </Alert>

      {/* Alerta de modificación */}
      {isEditModalVisible && (
        <div
          className={`${styles.overlay} ${isEditModalVisible ? styles.visible : ""}`}
        >
          <div className={styles.modalBox}>
            <h3 className={styles.title}>Editar Sucursal</h3>
            <div className={styles.body}>
              <input
                type="text"
                value={selectedSucursal?.nombreSucursal || ""}
                onChange={(e) =>
                  setSelectedSucursal({
                    ...selectedSucursal,
                    nombreSucursal: e.target.value,
                  })
                }
                placeholder="Nombre de la sucursal"
              />
              <input
                type="tel"
                value={selectedSucursal?.telefono || ""}
                onChange={(e) =>
                  setSelectedSucursal({
                    ...selectedSucursal,
                    telefono: e.target.value,
                  })
                }
                placeholder="Número de teléfono"
              />
              <input
                type="text"
                value={selectedSucursal?.direccion || ""}
                onChange={(e) =>
                  setSelectedSucursal({
                    ...selectedSucursal,
                    direccion: e.target.value,
                  })
                }
                placeholder="Dirección"
              />
            </div>
            <div className={styles.footer}>
              <Button
                label="Confirmar"
                variant="canceled"
                size="medium"
                onClick={handleConfirmUpdate}
                className={styles.confirm}
              />
              <Button
                label="Cancelar"
                variant="default"
                size="medium"
                onClick={handleCloseEditModal}
                className={styles.cancel}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sucursales;
