import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs";
import DashboardLayout from "../layouts/DashboardLayout";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { QRCodeCanvas } from "qrcode.react";
import styles from "../css/compartir.module.css";

const Compartir = () => {
  const navigate = useNavigate();
  const qrRef = useRef(null); // Ref para el QRCodeCanvas
  const restaurantId = localStorage.getItem("userid");
  const [restaurantName, setRestaurantName] = useState();

  useEffect(() => {
    setRestaurantName(localStorage.getItem("restaurantName"));
    if (!restaurantId) {
      navigate("/login"); // Redirigir si no hay sesión
    }
  }, [navigate, restaurantId]);

  //console.log("restaurantId:",restaurantId)
  //console.log("restaurantName:",restaurantName)
  const menuLink = `https://espallevar.com/${restaurantName}`; // Enlace estático del menú
  const predefinedMessage = `¡Hola! Echa un vistazo a nuestro delicioso menú en línea: ${menuLink}`;

  // Función para descargar el QR como imagen
  const downloadQR = () => {
    const canvas = qrRef.current.querySelector("canvas"); // Captura el canvas dentro del QRCodeCanvas
    const image = canvas.toDataURL("image/png"); // Convierte el canvas a una URL de imagen
    const link = document.createElement("a"); // Crea un elemento <a>
    link.href = image; // Asocia la URL de la imagen al href
    link.download = "qr-menu.png"; // Establece el nombre del archivo
    link.click(); // Simula un clic para descargar
  };

  return (
    <DashboardLayout>
      <Breadcrumbs items={[{ label: "Compartir", link: "/compartir" }]} />
      <hr className="solid" />

      <div className={styles.container}>
        {/* Sección 1: Código QR */}
        <div className={styles.section}>
          <div className={styles.qrSection} ref={qrRef}>
            <QRCodeCanvas value={menuLink} size={128} />
            <p>Escanea el código QR para ver el menú.</p>
          </div>
          <div style={{ textAlign: "center" }}>
            <button className={styles.button} onClick={downloadQR}>
              Descargar QR
            </button>
          </div>
        </div>

        {/* Sección 2: Enlace para el menú */}
        <div className={styles.section}>
          <div className={styles.inputContainer}>
            <input
              type="text"
              value={menuLink}
              readOnly
              className={styles.inputField}
            />
            <CopyToClipboard text={menuLink}>
              <button className={styles.button}>Copiar enlace</button>
            </CopyToClipboard>
          </div>
          <div className={styles.buttonContainer}>
            <a href={menuLink} target="_blank" rel="noopener noreferrer">
              <button className={styles.button}>Ver Menú</button>
            </a>
          </div>
        </div>

        {/* Sección 3: Mensaje predefinido y WhatsApp */}
        <div className={styles.section}>
          <textarea
            readOnly
            value={predefinedMessage}
            className={styles.textarea}
          />
          <p>Este es un mensaje que puedes copiar y compartir por mensaje:</p>
          <div className={styles.buttonContainer}>
            <a
              href={`https://wa.me/?text=${encodeURIComponent(predefinedMessage)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className={styles.button}>Compartir en WhatsApp</button>
            </a>
            <CopyToClipboard text={predefinedMessage}>
              <button className={styles.button}>Copiar mensaje</button>
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Compartir;
